import React, { useState, useEffect } from "react";
import { Stepper, Step } from "@material-tailwind/react";
import Video from "../assets/videos/HeroSectionVideo.mp4";
import handShake from "../assets/images/Experience And Expertise.jpg";
import Safety from "../assets/images/Security And Safety.jpg";
import Efficiency from "../assets/images/Efficient and Transparent.jpg";
import { FaUsersCog } from "react-icons/fa";
import { IoMdBusiness } from "react-icons/io";
import { GiArchiveResearch } from "react-icons/gi";
import { FaGlobe } from "react-icons/fa";
import { MdLocationCity } from "react-icons/md";
import { GrTechnology } from "react-icons/gr";

const Home = () => {
  useEffect(() => {
    document.title = "Work Permit Consultancy - Home";
  }, []);
  const [activeStep, setActiveStep] = useState(0);

  const [isLastStep, setIsLastStep] = useState(false);
  const [stepperClicked, setStepperClicked] = useState(false);
  useEffect(() => {
    let timer;
    if (!stepperClicked) {
      timer = setTimeout(() => {
        if (activeStep < 3) {
          setActiveStep(activeStep + 1);
        } else {
          setActiveStep(0);
        }
      }, 5000);
    }

    return () => clearTimeout(timer);
  }, [activeStep, isLastStep, stepperClicked]);

  const steps = [
    {
      id: 0,
      Heading: "Sourcing & Recruitment Services",
      content:
        "This process involves identifying potential candidates from an international pool, scheduling Skype interviews promptly, and finalizing the selection based on client preferences.",
    },
    {
      id: 1,
      Heading: "Hiring & Authorization Services",
      content:
        "Arrange a Dutch employment contract meeting, meet income requirements. Apply through the recognized IND sponsorship program, receive confirmation and decision. Collect MVV from the country of origin.",
    },
    {
      id: 2,
      Heading: "Relocation & Legalization Services",
      content:
        "Facilitate expert's travel arrangements and pick-up, including temporary housing. Optionally, coordinate household item relocation and secure long-term housing. Schedule IND appointment, apply for BSN, Dutch bank account, health insurance, and town hall registration swiftly",
    },
    {
      id: 3,
      Heading: "Onboarding & Deployment Services",
      content:
        "Establish performance objectives collaboratively with the client and formulate a technical onboarding plan. Regularly gather client feedback quarterly and organize mandatory optional training and mentoring for deployed staff.",
    },
  ];
  const cards = [
    {
      icon: (
        <MdLocationCity className="text-5xl text-myBlue hover:text-blue-500" />
      ),
      heading: "Strategic Location and Connectivity",
      text: "Excellent connectivity with major markets, advanced infrastructure, and robust digital networks.",
    },
    {
      icon: (
        <IoMdBusiness className="text-5xl text-myBlue hover:text-blue-500" />
      ),
      heading: "Supportive Business Environment",
      text: "Active government support, vibrant startup ecosystem, and diverse funding opportunities.",
    },
    {
      icon: <FaUsersCog className="text-5xl text-myBlue hover:text-blue-500" />,
      heading: "Highly Skilled Workforce",
      text: "Strong education system, multilingual talent, and a culturally diverse work environment.",
    },
    {
      icon: (
        <GrTechnology className="text-5xl text-myBlue hover:text-blue-500" />
      ),
      heading: "Thriving Tech Community",
      text: "Industry clusters, leading tech companies, and a collaborative tech community with regular meetups and conferences.",
    },
    {
      icon: (
        <GiArchiveResearch className="text-5xl text-myBlue hover:text-blue-500" />
      ),
      heading: "Innovation and Research",
      text: "Significant R&D investments and collaboration between academia and industry, driving technological advancements.",
    },
    {
      icon: <FaGlobe className="text-5xl text-myBlue hover:text-blue-500" />,
      heading: "Quality of Life",
      text: "Excellent work-life balance, high standard of living, and commitment to sustainability.",
    },
  ];

  return (
    <div className="home">
      <div className="relative h-[90vh] heroSection">
        <video
          className="w-full h-full object-cover"
          autoPlay
          muted
          loop
          playsInline
        >
          <source src={Video} type="video/mp4" />
        </video>
        <div className="absolute inset-0 bg-black opacity-20" />

        <div className="flex flex-col mt-[15%] items-center justify-center absolute inset-0 ">
          <div className="bg-white rounded-[10px] opacity-80 text-center lg:w-[35%] md:w-[50%] sm:w-[60%] w-[65%] p-7">
            <h1 className=" font-playfairDisplay tracking-wide md:text-4xl sm:text-3xl text-2xl font-extrabold mb-4 text-myBlue w-[100%]">
              Work Permit Consultancy
            </h1>
            <p className="font-Poppins text-lg md:text-xl mx-2 mb-6">
              We are here to guide you through the process of obtaining your
              work permit and provide expert consultancy services to make your
              transition seamless.
            </p>
          </div>
          <a
            href="/apply-now"
            className="text-sm font-Poppins font-semibold leading-6  border-2 border-myBlue hover:border-2  hover:border-myBlue hover:bg-white hover:opacity-80 hover:text-myBlue py-3 px-6 rounded-[10px] m-5 bg-myBlue text-white"
          >
            Apply Now
          </a>
        </div>
      </div>
      <div className="howItWorks  bg-[#F2F7FF] m-0 py-5">
        <div className=" w-[85%] mx-auto max-w-7xl">
          <h2 className="text-center text-3xl py-5 font-playfairDisplay font-semibold text-myBlue">
            How it Works
          </h2>
          <div className=" md:grid md:grid-cols-2 gap-0 place-items-center ">
            <div className="py-6">
              <p className="py-2 px-4 font-Poppins">
                Begin by reaching out to us and sharing your aspirations. Our
                dedicated team is here to understand your needs and guide you
                through the process.We will provide Personal Consultation,
                Document Support, Application Submissions and also post-approval
                guidance and support
              </p>
              <div className="flex justify-center items-center my-4">
                <a
                  href="/apply-now"
                  className="text-xs font-Poppins font-semibold leading-6  border-2 border-myBlue  hover:border-myBlue hover:bg-white hover:text-myBlue rounded-3xl px-5 py-2 bg-myBlue text-white"
                >
                  Apply Now
                </a>
              </div>
            </div>

            <div className="w-full mt-0 py-4 px-8 bg-white rounded-[10px]">
              <Stepper
                className="w-[85%] mx-auto"
                activeStep={activeStep}
                activeLineClassName="!bg-myBlue text-white "
                isLastStep={(value) => setIsLastStep(value)}
              >
                {steps.map((step) => (
                  <Step
                    key={step.id}
                    activeClassName="!bg-myBlue text-white"
                    completedClassName="!bg-myBlue text-white"
                    onClick={() => {
                      setStepperClicked(true);
                      setActiveStep(step.id);
                    }}
                  >
                    {step.id + 1}
                  </Step>
                ))}
              </Stepper>
              {steps.map((step) => (
                <div
                  key={step.id}
                  className={`${activeStep === step.id ? "" : "hidden"} `}
                >
                  <div
                    className="text-center  bg-[#F2F7FF] rounded-[10px] my-3"
                    onMouseEnter={() => {
                      setStepperClicked(true);
                    }}
                    onMouseLeave={() => {
                      setStepperClicked(false);
                    }}
                  >
                    <h2 className="py-1 font-Roboto font-semibold text-myBlue">
                      {step.Heading}
                    </h2>
                    <p className="py-2 px-5">{step.content}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="whoWeAre md:bg-cover md:bg-scroll bg-center">
        <h2 className="text-center text-3xl py-5 font-playfairDisplay font-semibold text-myBlue">
          Who We Are
        </h2>
        <div className="w-[85%] mx-auto max-w-7xl">
          <div className="md:w-[50%] p-5">
            <p className=" font-Poppins">
              We are more than just a service .We are your dedicated ally on the
              path to securing a work permit in the Netherlands.With a
              commitment to excellence and a deep understanding of Dutch
              immigration processes, our seasoned professionals are here to
              simplify your journey.Whether you're an individual seeking new
              opportunities or an employer navigating workforce mobility, We
              stand as your reliable partner.
            </p>
            <div className="flex justify-center items-center my-4">
              <a
                href="/apply-now"
                className=" text-xs font-Poppins font-semibold leading-6  border-2 border-myBlue  hover:border-myBlue hover:bg-transparent hover:text-myBlue rounded-3xl px-5 py-2 bg-myBlue text-white"
              >
                Apply Now
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="WhyChooseUs bg-[#f2f7ff] ">
        <h2 className="text-center text-3xl py-5 font-playfairDisplay font-semibold text-myBlue">
          Why Choose Us
        </h2>
        <div className="flex flex-col md:flex-row items-center md:justify-center py-5 max-w-[83rem] mx-auto">
          {/* Left Div */}
          <div className=" order-1 w-[95%] md:w-[50vw] 2xl:w-[45vw] md:h-[470px]  md:order-1 flex-shrink-0 mb-4 md:mb-0 md:ml-4 rounded-[10px]">
            <img
              src={handShake}
              alt="Left Image"
              className="object-cover w-full h-full rounded-[10px] "
            />
          </div>

          {/* Right Div */}
          <div className="order-2 w-[85%] md:w-[44rem] md:order-2 md:mt-auto -mt-[25%] mx-auto md:-ml-[8%]  md:mr-4 rounded-[10px] bg-[#d7e7ff] !opacity-85 mb-8 pt-3">
            <div className="text-center p-5 ">
              <h2 className=" font-Poppins font-semibold  text-myBlue">
                Experience And Expertise
              </h2>
              <p className=" ">
                Entrust your work permit journey to a team with unparalleled
                expertise in Dutch immigration laws and procedures. Our seasoned
                professionals bring years of experience, ensuring accurate and
                up-to-date guidance tailored to your unique
                situation.Recognizing the individuality of each case, we provide
                personalized solutions to address your specific needs and
                concerns.
              </p>
              <div className="flex justify-center items-center my-4">
                <a
                  href="/apply-now"
                  className=" text-xs font-Poppins font-semibold leading-6  border-2 border-myBlue  hover:border-myBlue hover:bg-transparent hover:text-myBlue rounded-3xl px-5 py-2 bg-myBlue text-white"
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*Second Section */}

        <div className="bg-white">
          <div className="flex flex-col md:flex-row items-center md:justify-center py-5 max-w-[83rem] mx-auto ">
            {/* Left Div */}
            <div className="order-1 w-[95%] md:w-[50vw] 2xl:w-[45vw] md:h-[470px]  md:order-2 flex-shrink-0 mb-4 md:mb-0 md:mr-4 rounded-[10px]">
              <img
                src={Safety}
                alt="Left Image"
                className="object-cover w-full h-full rounded-[10px] "
              />
            </div>

            {/* Right Div */}
            <div className="order-2 z-10 w-[85%] md:w-[44rem] md:order-1 md:mt-auto -mt-[25%] mx-auto md:-mr-[8%] md:mb-8 md:ml-4 rounded-[10px] bg-[#d7e7ff] !opacity-85 mb-8 pt-3">
              <div className="text-center p-5">
                <h2 className=" font-Poppins font-semibold  text-myBlue">
                  Safe And Secure{" "}
                </h2>
                <p className=" ">
                  Your data and confidentiality are of the utmost importance. We
                  prioritize safety and security with state-of-the-art security
                  measures, ensuring your personal information remains
                  confidential and protected from unauthorized access.
                </p>
                <div className="flex justify-center items-center my-4">
                  <a
                    href="/apply-now"
                    className=" text-xs font-Poppins font-semibold leading-6  border-2 border-myBlue  hover:border-myBlue hover:bg-transparent hover:text-myBlue rounded-3xl px-5 py-2 bg-myBlue text-white"
                  >
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Third Section */}
        <div className="flex flex-col md:flex-row items-center md:justify-center py-5 max-w-[83rem] mx-auto">
          {/* Left Div */}
          <div className=" order-1 w-[95%] md:w-[50vw] 2xl:w-[45vw] md:h-[470px]  md:order-1 flex-shrink-0 mb-4 md:mb-0 md:ml-4 rounded-[10px]">
            <img
              src={Efficiency}
              alt="Left Image"
              className="object-cover w-full h-full rounded-[10px] "
            />
          </div>

          {/* Right Div */}
          <div className="order-2 w-[85%] md:w-[44rem] md:order-2 md:mt-auto -mt-[25%] mx-auto md:-ml-[8%]  md:mr-4 rounded-[10px] bg-[#d7e7ff] !opacity-85 mb-8 pt-3">
            <div className="text-center px-5 py-4">
              <h2 className=" font-Poppins font-semibold text-myBlue">
                Efficient and Transparent
              </h2>
              <p className=" ">
                Time matters, and we optimize efficiency in every aspect of the
                process. From streamlined application submissions to swift
                response times, our focus is on minimizing delays and delivering
                results within optimal timeframes.Stay informed at every stage
                with transparent updates on your application progress. Our
                commitment to timely and clear communication ensures you are
                always in the know.
              </p>
              <div className="flex justify-center items-center my-4">
                <a
                  href="/apply-now"
                  className=" text-xs font-Poppins font-semibold leading-6  border-2 border-myBlue  hover:border-myBlue hover:bg-transparent hover:text-myBlue rounded-3xl px-5 py-2 bg-myBlue text-white"
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="py-12 bg-white w-[90%] mx-auto max-w-7xl">
        <div className="text-center py-5">
          <h2 className=" text-center text-3xl  font-playfairDisplay font-semibold text-myBlue mb-2">
            Why Netherlands
          </h2>
          <p className="text-xl mb-12">
            Discover the benefits of pursuing your tech career in the
            Netherlands
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {cards.map((card, index) => (
              <div key={index} className="flex  p-2 bg-white rounded-lg ">
                <div className="mr-5">{card.icon}</div>
                <div className=" text-left">
                  <div className="font-bold text-lg ">{card.heading}</div>
                  <div className="text-gray-600">{card.text}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
