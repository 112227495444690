import React from "react";
import { Link } from "react-router-dom";

const RedirectUser = () => {
  return (
    <div className="flex justify-center items-center relative ">
      <div className="flex  items-center justify-center h-[60vh]">
        <div className="text-center">
          <h1 className="text-3xl text-myBlue font-playfairDisplay font-bold mb-4">
            Welcome to Work Permit Consultancy
          </h1>
          <p className="text-gray-700 font-Roboto mb-8">
            Please sign in or go to the home page to access this content.
          </p>
          <div className="space-x-4">
            <Link
              to="/"
              className=" font-Roboto font-semibold bg-[#219ebc] text-white py-2 px-5 rounded  hover:bg-white hover:outline-2 hover:border-2 hover:border-[#219ebc] hover:text-[#219ebc]"
            >
              Home
            </Link>
            <Link
              to="/sign-in"
              className="bg-myBlue text-white py-2 px-4 rounded hover:bg-white hover:outline-2 hover:border-2 hover:border-myBlue hover:text-myBlue font-Roboto font-semibold"
            >
              Sign In
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedirectUser;
