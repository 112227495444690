import React, { useState, useEffect } from "react";
import Testimonials from "../components/testimonials/Testimonials";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import GroupImage from "../assets/images/Work Permit Consultancy About us.jpg";
import history from "../assets/images/history.png";
import achievement from "../assets/images/achievements.png";
import goals from "../assets/images/goals.png";

const AboutUs = () => {
  useEffect(() => {
    document.title = "Work Permit Consultancy - About Us";
  }, []);

  const [countOn, setCountOn] = useState(false);
  const cardData = [
    {
      image: `${history}`,
      title: "History",
      description:
        "Our story began with a passion for simplifying the work permit journey. From our humble beginnings to becoming a trusted name in Dutch immigration consultancy, our history shapes our commitment to excellence and client success.",
    },
    {
      image: `${achievement}`,
      title: "Achievements",
      description:
        "Our success is measured by the countless work permits secured and the professional journeys transformed. Explore the achievements that underscore our expertise and dedication to making dreams a reality.",
    },
    {
      image: `${goals}`,
      title: "Goals",
      description:
        "Our goals extend beyond the present, aiming to enhance and innovate the work permit consultancy experience. Committed to continuous improvement, we aspire to set new standards, ensuring every client's journey is even more seamless and successful.",
    },
  ];
  return (
    <div>
      <div className="bg-[#f2f7ff] flex flex-col lg:flex-row reletive ">
        <div className="lg:w-[55%] my-auto  ">
          <div className="lg:ml-auto md:mx-4 my-0 lg:pl-[1rem] lg:pr-[4rem] xl:pl-[2rem] 2xl:pl-[3rem] about-us-hero">
            <h1 className=" text-center font-playfairDisplay tracking-wide 2xl:text-5xl md:text-4xl sm:text-3xl text-2xl font-extrabold mb-4 text-myBlue w-[100%]">
              About Us
            </h1>
            <p className="font-Poppins  text-lg md:text-xl mx-[15px] mb-6 2xl:text-3xl">
              As your dedicated partner in work permit consultancy, we blend
              expertise with personalized support, guiding you through the
              intricacies of Dutch immigration
            </p>
            <div className="flex justify-center items-center my-4">
              <a
                href="/apply-now"
                className=" text-xs 2xl:text-2xl font-Poppins font-semibold leading-6  border-2 border-myBlue  hover:border-myBlue hover:bg-transparent hover:text-myBlue rounded-3xl px-5 py-2 bg-myBlue text-white"
              >
                Apply Now
              </a>
            </div>
          </div>
        </div>
        <div className="lg:w-[45%]  overflow-hidden">
          <img src={GroupImage} className=" object-fill" />
        </div>
      </div>
      <div className="cards max-w-[83rem] mx-auto">
        <h2 className="text-center 2xl:text-5xl md:text-4xl sm:text-3xl text-2xl py-5 font-playfairDisplay font-semibold text-myBlue">
          Who We Are
        </h2>
        <div className="flex flex-wrap  justify-center  mx-auto">
          {cardData.map((card, index) => (
            <div
              key={index}
              className="max-w-sm rounded border-2 overflow-hidden shadow-lg m-4 bg-[#f4f7ff]"
            >
              <img
                className="w-full h-48 object-cover bg-white"
                src={card.image}
                alt="Card"
              />
              <div className="px-6 py-4 ">
                <div className="font-bold text-xl mb-2 text-myBlue">
                  {card.title}
                </div>
                <p className="text-gray-700 text-base">{card.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="our_impact">
        <h2 className="text-center 2xl:text-5xl md:text-4xl sm:text-3xl text-2xl py-5 font-playfairDisplay font-semibold text-myBlue">
          Success at a Glance
        </h2>
        <div className="flex flex-col justify-center  h-[100dvh] sm:h-[50dvh]  relative">
          <div className="h-[55%] bg-white relative"></div>
          <div className="h-[45%] bg-[#f4f7ff]"></div>
          <div className="z-10 absolute h-[75dvh] sm:h-[50dvh] my-3 flex flex-wrap sm:flex-nowrap flex-row justify-around items-center w-[80%] left-1/2 transform -translate-x-1/2">
            <div className=" w-[155px] h-[180px] sm:w-[175px] sm:h-[200px] md:w-[200px] md:h-[250px] lg:w-[230px] rounded overflow-hidden shadow-lg bg-[#f6f6f6] p-4 sm:p-6 text-center mb-4 sm:mb-0">
              <img
                className="w-16 h-16 md:w-20 md:h-20 rounded mx-auto mb-2 "
                src={require("../assets/images/Satisfied Customers.png")}
                alt="Card"
              />
              <ScrollTrigger
                onEnter={() => setCountOn(true)}
                onExit={() => setCountOn(false)}
              >
                <div className="text-[26px] md:text-[30px] lg:text-[36px] bg-gradient-to-b from-amber-800 to-yellow-300 bg-clip-text text-transparent font-bold mb-1 md:mb-2">
                  {countOn && (
                    <CountUp
                      start={0}
                      end={435}
                      delay={0}
                      duration={6}
                      separator=","
                      suffix="+"
                    />
                  )}
                </div>
              </ScrollTrigger>
              <p className="text-sm md:text-base">Satisfied Customers</p>
            </div>

            <div className="w-[155px] h-[180px] sm:w-[175px] sm:h-[200px] md:w-[200px] md:h-[250px] lg:w-[230px] rounded overflow-hidden shadow-lg bg-[#f6f6f6] p-4 sm:p-6 text-center mb-4 sm:mb-0">
              <img
                className="w-16 h-16 md:w-20 md:h-20 rounded mx-auto mb-2 "
                src={require("./../assets/images/Experienced Consultants.png")}
                alt="Card"
              />
              <ScrollTrigger
                onEnter={() => setCountOn(true)}
                onExit={() => setCountOn(false)}
              >
                <div className="text-[26px] md:text-[30px] lg:text-[36px] bg-gradient-to-b from-amber-800 to-yellow-300 bg-clip-text text-transparent  font-bold mb-1 md:mb-2">
                  {countOn && (
                    <CountUp
                      start={0}
                      end={85}
                      delay={0}
                      duration={6}
                      separator=","
                      suffix="+"
                    />
                  )}
                </div>
              </ScrollTrigger>
              <p className="text-sm md:text-base ">Experienced Consultants</p>
            </div>

            <div className="w-[155px] h-[180px] sm:w-[175px] sm:h-[200px] md:w-[200px] md:h-[250px] lg:w-[230px] rounded overflow-hidden shadow-lg bg-[#f6f6f6] p-4 sm:p-6 text-center">
              <img
                className="w-16 h-16 md:w-20 md:h-20 rounded mx-auto mb-2 "
                src={require("./../assets/images/Success Rate.png")}
                alt="Card"
              />
              <ScrollTrigger
                onEnter={() => setCountOn(true)}
                onExit={() => setCountOn(false)}
              >
                <div className=" text-[26px] md:text-[30px] lg:text-[36px] font-bold bg-gradient-to-b from-amber-800 to-yellow-300 bg-clip-text text-transparent  mb-1 md:mb-2">
                  {countOn && (
                    <CountUp
                      start={0}
                      end={98}
                      delay={0}
                      duration={6}
                      separator=","
                      suffix="%"
                    />
                  )}
                </div>
              </ScrollTrigger>
              <p className="text-sm md:text-base ">Success Rate</p>
            </div>
          </div>
        </div>
      </div>
      <div className="py-5 bg-[#f4f7ff]">
        <h2 className="text-center 2xl:text-5xl md:text-4xl sm:text-3xl text-2xl py-5 font-playfairDisplay font-semibold text-myBlue">
          What They Say
        </h2>
        <Testimonials />
      </div>
    </div>
  );
};

export default AboutUs;
