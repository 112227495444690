import React from "react";
import LegalHeader from "../../components/legal/LegalHeader";
import InformationSecurity from "../../assets/images/svg/info-security-policy.svg";
const ServiceDetailItem = ({ title, description }) => (
  <li className="hover:text-[#093372] leading-8">
    {title}
    {description && (
      <p className="text-sm text-gray-400 font-normal">{description}</p>
    )}
  </li>
);

const InformationSecurityPolicy = () => {
  const cookieTypesDetails = [
    {
      title:
        "Assigning dedicated personnel and allocating budget to security management.",
    },
    {
      title:
        "Implementing appropriate security technology and high-availability, recoverable systems and facilities.",
    },
    {
      title:
        "Continually evaluating and improving procedures related to security.",
    },
    {
      title:
        "Adopting and enforcing requisite policies and ensuring that employees are kept aware of the ISMS and their responsibilities towards it via communication and training programmes.",
    },
    {
      title:
        "Striving to maintain compliance with all applicable legal and industry requirements.",
    },
  ];
  return (
    <div>
      <LegalHeader title="Information Security Policy" />
      <div className="container max-w-7xl m-auto  p-4 sm:p-0">
        <div className=" py-4 text-center">
          <div className="container mx-auto flex flex-col items-center justify-center md:flex-row">
            <div className="w-full md:w-[50%]">
              <div className="text-justify my-auto">
                <p className="leading-8">
                  The group of WPC Companies have controls to protect the
                  confidentiality, integrity, and availability of information
                  that is owned by or entrusted to them. The intent of this
                  document is to provide assurances to customers, potential
                  customers, and any other interested parties that information
                  in our companies custody is properly protected - and that the
                  protections in place are consistent with appropriate
                  compliance requirements.
                </p>
                <h1 className="text-xl font-bold my-4">Overview</h1>
                <p className="leading-8">
                  WPC Companies provide consultancy services, The Boards of
                  Directors and management of WPC Companies are committed to
                  preserving the confidentiality, integrity and availability of
                  all the physical and electronic information assets throughout
                  their organisation to maintain legal, regulatory and
                  contractual compliance and to safeguard business integrity and
                  commercial reputation.
                </p>
              </div>
            </div>
            <div className="w-full md:w-[50%]">
              <img
                src={InformationSecurity}
                alt="Privacy Policy"
                className="img-responsive w-[60%] mx-auto"
              />
            </div>
          </div>
          <div className="container mx-auto flex flex-col items-center justify-center md:flex-row">
            <div className="w-full">
              <div className="text-justify my-auto ">
                <p className="leading-8 mt-4">
                  To achieve this, WPC Companies have implemented a Group-wide
                  Information Security Management System (ISMS) in accordance
                  with the international standard ISO/IEC 27001:2013
                  requirements. The ISMS is subject to continuous systematic
                  review and improvement.
                </p>
                <p className="leading-8 mt-4">
                  In accordance with the ISMS, WPC Companies demonstrate their
                  commitment to information security by:
                </p>
                <ul className="list-disc list-inside">
                  {cookieTypesDetails.map((detail, index) => (
                    <ServiceDetailItem key={index} title={detail.title} />
                  ))}
                </ul>
                <p className="leading-8">
                  WPC Companies use security policies and standards to support
                  business objectives within their information systems and
                  processes. These policies and standards are implemented,
                  communicated, and reviewed on a regular basis and reflect the
                  executive management teams commitment to information security.
                  Policies and standards are in place to govern the protection
                  of each companys information assets and any information assets
                  of our customers (and others) that have been entrusted to a
                  WPC company.
                </p>

                <h1 className="text-xl font-bold my-4">Staff</h1>
                <p className="leading-8">
                  WPC Companies employ staff whose responsibility is the
                  protection of information. In addition, it is the
                  responsibility of all of employees to be aware of information
                  security issues within their daily work. To promote awareness,
                  employees of WPC Companies are provided with training on
                  topics such as the companys security policies, their
                  responsibilities to protect the confidentiality of information
                  entrusted to them, the appropriate use of resources, the extra
                  care required for the protection of mobile devices, and other
                  related topics.
                </p>
                <h1 className="text-xl font-bold my-4">
                  Confidentiality Agreements
                </h1>
                <p className="leading-8">
                  WPC Companies enter into confidentiality or non-disclosure
                  agreements with their vendors, contractors, employees and
                  clients to contractually safeguard personal and other
                  confidential information belonging to a WPC company or in the
                  custody of a WPC company.
                </p>
                <h1 className="text-xl font-bold my-4">
                  Audits and Assessments
                </h1>
                <p className="leading-8">
                  Regular risk assessments are performed to help WPC Companies
                  identify any potential risks to their information assets and
                  to help prioritize efforts to mitigate those risks.
                  Periodically, companies also engage external firms to perform
                  more in-depth evaluations of their security controls by
                  conducting penetration testing and other similar exercises. In
                  addition to external reviews, internal tests are conducted on
                  a regular basis to ensure compliance and verify control
                  effectiveness. Vulnerability scans are conducted, and the
                  results of these scans are used to identify vulnerabilities to
                  be addressed and to prioritize the efforts of those staff that
                  are responsible for keeping the IT systems of WPC Companies up
                  to date and protected.
                </p>
                <h2 className="font-bold my-4">Physical Security</h2>
                <p className="leading-8">
                  All sites hosting information belonging to a WPC company (or
                  information that is managed by a WPC company on the behalf of
                  others) are secured. Such facilities are protected by physical
                  security barriers and entry controls designed to prevent
                  unauthorized access, damage, and interference. Fire
                  suppression, environmental controls, and uninterrupted power
                  supplies are all in place, as are security cameras to monitor
                  the facilities and all entrances to them.
                </p>
                <h2 className="font-bold my-4">Operational Security</h2>
                <p className="leading-8">
                  Responsibilities and procedures for the management and
                  operation of information processing facilities are established
                  and separation of duties by function have been implemented
                  across the companies that comprise the WPC Operational change
                  to systems is controlled through various defined change
                  management processes.
                </p>
                <h2 className="font-bold my-4">Access Control</h2>
                <p className="leading-8">
                  Access to information, information processing facilities, and
                  business processes are controlled on the basis of business and
                  security requirements. Access control rules take into account
                  the basic principle of "need-to-know" and the sensitivity of
                  corporate and personal information.
                </p>
                <p className="leading-8 mt-4">
                  Layers of security controls limit access to information. These
                  include controls at the network, application, operating
                  system, and database levels. Passwords are used in conjunction
                  with each of these layers; they are subject to defined
                  password construction rules and must be changed at regular
                  intervals. Password administration and management are
                  controlled processes that generate automated audit records.
                </p>
                <h2 className="font-bold my-4">Data Communications Security</h2>
                <p className="leading-8">
                  Technologies such as SSL (TLS), and IPsec are used to encrypt
                  data when in transit over public networks. The use of such
                  technologies is dependent upon the level of sensitivity of the
                  information, both corporate and personal.
                </p>
                <h2 className="font-bold my-4">Computer Security Measures</h2>
                <p className="leading-8">
                  Various security technologies are deployed within the
                  infrastructures and include firewalls, anti-virus,
                  antispyware, encryption, and intrusion detection systems and
                  processes.
                </p>
                <p className="leading-8 mt-4">
                  Security data is logged and regularly reviewed to identify
                  policy violations and security incidents. Incidents are
                  documented and investigated to determine severity, root cause,
                  and follow-up actions required. Measures to be taken to
                  prevent re-occurrence are also identified, documented, and
                  implemented as needed.
                </p>
                <h2 className="font-bold my-4">
                  Disaster Prevention and Recovery
                </h2>
                <p className="leading-8">
                  Adequate back-up capabilities exist to ensure that all
                  essential information and software can be recovered following
                  a disaster or media failure. Backup information is stored at a
                  remote secure location, at a sufficient distance to escape any
                  damage from a disaster at the primary site. Backup media is
                  protected against unauthorized access, misuse or corruption
                  during transportation beyond the data center boundaries.
                </p>
                <p className="leading-8 mt-4">
                  Combinations of preventive and recovery controls are
                  implemented to help protect from harm due to loss of data or
                  processing capabilities. These controls are designed based on
                  an assessment of risk and are meant to keep the harmful
                  effects of any outages to a minimum. The processes making up
                  these control measures are tested on a regular basis.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InformationSecurityPolicy;
