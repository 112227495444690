import React, { useEffect } from "react";
import { SignUp } from "@clerk/clerk-react";

const SignUpPage = () => {
  useEffect(() => {
    document.title = "Work Permit Consultancy - SignUp";
  }, []);

  return (
    <div className="SignIn flex items-center justify-center lg:h-screen bg-cover bg-center relative">
      {/* Darken overlay */}
      <div className="absolute inset-0 bg-black opacity-30"></div>

      {/* Centered container */}
      <div className="relative flex  flex-wrap items-center justify-center z-10 my-[10%] rounded-lg max-w-[90%] lg:h-[98%] w-full text-center ">
        {/* First Div */}
        <div className="LoginFirstDiv w-full h-1/2 lg:w-1/2 lg:h-full p-4 lg:mb-0 flex flex-col justify-center items-center">
          <div className="w-[80%] m-[10%] lg:w-[60%]">
            <h2 className="text-2xl sm:text-4xl font-playfairDisplay font-bold text-white mb-2">
              Work Permit Consultancy
            </h2>
            <p className="text-[#dcdcdc] font-Poppins">
              Your trusted partner for obtaining work permits, specialize in
              simplifying the application process and providing expert
              consultancy services
            </p>
          </div>
        </div>

        {/* Second Div */}
        <div className="w-full h-1/2 lg:w-1/2 lg:h-full flex flex-col justify-center items-center bg-[#f4f7ff]">
          <div className="w-[95%]">
            <SignUp signInUrl="/sign-in" afterSignUpUrl="/" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
