import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const testimonials = [
    {
      id: 1,
      title: "Emily Chen",
      content:
        " Navigating the Dutch work permit process seemed daunting, but these guys made it seamless! The personalized guidance and expertise of their consultants ensured my application was smooth, and I'm now thriving in my new role. Highly recommended!",
      stars: 5,
    },
    {
      id: 2,
      title: "Samantha Johnson",
      content:
        "I can't thank enough for their support in securing my work permit. From BSN application to health insurance, they guided me at every step. Their attention to detail and commitment to my success were outstanding.",
      stars: 5,
    },
    {
      id: 3,
      title: "Alex Rodriguez",
      content:
        "Securing a Dutch work permit was a major milestone for me, and I couldn't have done it without the incredible assistance of these professionals. From the initial consultation to the final approval, their team was with me every step of the way.Their in-depth knowledge of the requirements made all the difference. I would recommend them without hesitation!",
      stars: 5,
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    beforeChange: (current, next) => setCurrentIndex(next),
  };

  return (
    <Slider {...settings} className="mx-auto w-[67%] my-3">
      {testimonials.map((testimonial) => (
        <div
          key={testimonial.id}
          className="text-left text-white p-5  bg-myBlue rounded-t-[30px] rounded-r-[30px]  "
        >
          <h2 className="text-xl font-bold mb-2">{testimonial.title}</h2>

          <p className="text-[#E1E1E1] italic">
            <span className="text-2xl text-[#E7C05C]">"</span>
            {testimonial.content}{" "}
            <span className="text-2xl text-[#E7C05C]">"</span>
          </p>
          <div className="flex justify-center mt-4">
            {Array.from({ length: 5 }, (_, index) => (
              <span
                key={index}
                className={`text-[#E7C05C] ${
                  index < testimonial.stars
                    ? "fill-current"
                    : "fill-transparent"
                } text-3xl mx-1`}
              >
                ★
              </span>
            ))}
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default Testimonials;
