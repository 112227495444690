import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Navbar from "./components/header/Navbar";
import Footer from "./components/footer/Footer";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import SignInPage from "./pages/SignInPage";
import RedirectUser from "./pages/RedirectUser";
import SignUpPage from "./pages/SignUpPage";
import ApplyNow from "./pages/ApplyNow";
import Dashboard from "./pages/Dashboard";
import { SignedOut, SignedIn } from "@clerk/clerk-react";
import TermsAndConditions from "./pages/legal/TermsAndConditions";
import PrivacyPolicy from "./pages/legal/PrivacyPolicy";
import CookiePolicy from "./pages/legal/CookiePolicy";
import InformationSecurityPolicy from "./pages/legal/InformationSecurityPolicy";
import { Spinner } from "@material-tailwind/react";

const App = () => {
  const [cookiesPop, setCookiesPop] = useState(true);
  const [loading, setLoading] = useState(false);
  const [decline, setDecline] = useState(false);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (cookiesAccepted === "true") {
      setCookiesPop(false);
    }
  }, []);
  const handleAccept = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      localStorage.setItem("cookiesAccepted", "true");
      setCookiesPop(false);
    }, 2000);
  };

  const handleDecline = () => {
    setDecline(true);
    setTimeout(() => {
      setDecline(false);
      localStorage.setItem("cookiesAccepted", "false");
      setCookiesPop(false);
    }, 2000);
  };
  return (
    <>
      <Routes>
        <Route path="/sign-in" element={<SignInPage />} />
        <Route path="/sign-up" element={<SignUpPage />} />
      </Routes>
      <SignedOut>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navbar />
                <Home />
                <Footer />
              </>
            }
          />

          <Route
            path="/about-us"
            element={
              <>
                <Navbar />
                <AboutUs />
                <Footer />
              </>
            }
          />

          <Route
            path="/apply-now"
            element={
              <>
                <Navbar />
                <RedirectUser />
                <Footer />
              </>
            }
          />
          <Route
            path="/dashboard"
            element={
              <>
                <Navbar />
                <RedirectUser />
                <Footer />
              </>
            }
          />
          <Route
            path="/information-security"
            element={
              <>
                <Navbar />
                <InformationSecurityPolicy />
                <Footer />
              </>
            }
          />
          <Route
            path="/cookie-policy"
            element={
              <>
                <Navbar />
                <CookiePolicy />
                <Footer />
              </>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <>
                <Navbar />
                <PrivacyPolicy />
                <Footer />
              </>
            }
          />
          <Route
            path="/terms-and-conditions"
            element={
              <>
                <Navbar />
                <TermsAndConditions />
                <Footer />
              </>
            }
          />
        </Routes>
      </SignedOut>
      <SignedIn>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navbar />
                <Home />
                <Footer />
              </>
            }
          />
          <Route
            path="/about-us"
            element={
              <>
                <Navbar />
                <AboutUs />
                <Footer />
              </>
            }
          />
          <Route
            path="/apply-now"
            element={
              <>
                <Navbar />
                <ApplyNow />
                <Footer />
              </>
            }
          />
          <Route
            path="/dashboard"
            element={
              <>
                <Navbar />
                <Dashboard />
                <Footer />
              </>
            }
          />
          <Route
            path="/information-security"
            element={
              <>
                <Navbar />
                <InformationSecurityPolicy />
                <Footer />
              </>
            }
          />
          <Route
            path="/cookie-policy"
            element={
              <>
                <Navbar />
                <CookiePolicy />
                <Footer />
              </>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <>
                <Navbar />
                <PrivacyPolicy />
                <Footer />
              </>
            }
          />
          <Route
            path="/terms-and-conditions"
            element={
              <>
                <Navbar />
                <TermsAndConditions />
                <Footer />
              </>
            }
          />
        </Routes>
      </SignedIn>
      {cookiesPop && (
        <div className="fixed bottom-[1rem] left-[1rem] sm:bottom-[2rem] sm:left-[2rem] max-w-[19rem] sm:max-w-sm px-4 py-3 shadow-md transition-all duration-500 ease-in-out rounded-[10px] bg-[#dbe9ff] z-20">
          <div className="flex flex-col px-0 ltr:lg:pl-9 rtl:lg:pr-9">
            <div className="w-full flex justify-between items-center">
              <p className="text-md md:text-lg font-semibold m-0 font-Poppins text-myBlue">
                Cookies & Privacy
              </p>
            </div>
            <div className="flex flex-col gap-2 items-stretch ltr:lg:pr-10 rtl:lg:pl-10">
              <div className="flex-1">
                <p className="my-1 text-xs md:text-sm font-Poppins">
                  We use cookies to improve your experience and ensure your
                  privacy. We don’t serve personalized ads or third
                  parties,Sensitive info is always secure.
                </p>
                <div className="flex text-center text-[12px] mt-1 gap-2">
                  <span className="ltr:mr-4 ltr:last:mr-0 rtl:ml-4 rtl:last:ml-0">
                    <a
                      href="/privacy-policy"
                      target="_blank"
                      className="no-underline cursor-pointer shrink-0 text-blue-700"
                    >
                      Privacy policy
                    </a>
                  </span>
                  <span className="ltr:mr-4 ltr:last:mr-0 rtl:ml-4 rtl:last:ml-0">
                    <a
                      href="/cookie-policy"
                      className="no-underline cursor-pointer shrink-0 text-blue-700"
                    >
                      Cookie policy
                    </a>
                  </span>
                </div>
              </div>
              <div className="flex flex-col justify-around mt-4 lg:mt-0 ltr:lg:pl-14 rtl:lg:pr-14">
                <div className="flex-1 gap-2  items-center flex my-0">
                  <button
                    onClick={handleDecline}
                    className="flex-1 lg:flex-none ltr:mr-2 rtl:ml-2 flex justify-center items-center text-center cursor-pointer px-2 md:px-4 py-2 border border-transparent text-xs leading-4 font-black"
                  >
                    <span className="flex ">
                      {decline && (
                        <Spinner
                          className="mr-2 h-4 w-4 text-myBlue "
                          color="white"
                        />
                      )}
                      Decline{" "}
                    </span>
                  </button>
                  <button
                    onClick={handleAccept}
                    className="flex-1 bg-myBlue rounded-[10px] text-white lg:flex-none flex justify-center items-center text-center cursor-pointer px-2 md:px-4 py-2 border border-transparent text-xs leading-4 font-black"
                  >
                    <span className="flex ">
                      {loading && (
                        <Spinner
                          className="mr-2 h-4 w-4 text-myBlue "
                          color="white"
                        />
                      )}
                      Accept{" "}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default App;
