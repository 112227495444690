import React from "react";
import LegalHeader from "../../components/legal/LegalHeader";
import terms from "../../assets/images/svg/terms-of-use.svg";

const ServiceDetailItem = ({ title, description }) => (
  <li className="hover:text-[#093372] leading-8">
    {title}
    {description && (
      <p className="text-sm text-gray-400 font-normal">{description}</p>
    )}
  </li>
);

const TermsAndConditions = () => {
  const termsOfUseDetails = [
    {
      title:
        "This web site contains material including text, photographs and other images and sound, which is protected by copyright and/or other intellectual property rights. All copyright and other intellectual property rights in this material are either owned by WPC Companies or have been licensed to it by the owner(s) of those rights so that it can use this material as part of this web site.",
    },
    {
      title:
        "This web site also contains trade marks & logos. All trade marks, & logos included on this web site belong to WPC or have been licensed to it by the owner(s) of those trade marks for use on this web site.",
    },
  ];

  const youMayDetails = [
    {
      title: "access any part of the web site;",
    },
    {
      title:
        "print off one copy of any or all of the pages for your own personal reference.",
    },
  ];
  const youMayNotDetails = [
    {
      title:
        "copy (whether by printing off onto paper, storing on disk, downloading or in any other way), distribute (including distributing copies), broadcast, alter or tamper with in any way or otherwise use any material contained in the web site. These restrictions apply in relation to all or part of the material on the web site;",
    },
    {
      title:
        "remove any copyright, trade mark or other intellectual property notices contained in the original material from any material copied or printed off from the web site; without our express written consent.",
    },
  ];
  return (
    <div>
      <LegalHeader title="Terms And Conditions" />
      <div className="container max-w-7xl m-auto  p-4 sm:p-0">
        <div className=" py-4 text-center">
          <div className="container mx-auto flex flex-col items-center justify-center md:flex-row">
            <div className="w-full md:w-[50%]">
              <div className="text-justify my-auto">
                <p className="leading-8">
                  Welcome to the Work Permit Consultancy website. This web site
                  is owned and operated by WPC Group of companies,whose
                  registered office is at Office Building A2 IFZA Business Park
                  Dubai Silicon Oasis,Dubai-UAE, The visitors to this web site
                  are bound by the following terms and conditions, please read
                  these carefully before you continue.
                </p>
                <h1 className="text-xl font-bold my-4">Terms of Use</h1>
                <ul className="list-disc list-inside">
                  {termsOfUseDetails.map((detail, index) => (
                    <ServiceDetailItem
                      key={index}
                      title={detail.title}
                      description={detail.description}
                    />
                  ))}
                </ul>
              </div>
            </div>
            <div className="w-full md:w-[50%]">
              <img
                src={terms}
                alt="Privacy Policy"
                className="img-responsive w-[60%] mx-auto"
              />
            </div>
          </div>
          <div className="container mx-auto flex flex-col items-center justify-center md:flex-row">
            <div className="w-full">
              <div className="text-justify my-auto">
                <h1 className="text-xl font-bold my-4">You may</h1>
                <ul className="list-disc list-inside">
                  {youMayDetails.map((detail, index) => (
                    <ServiceDetailItem key={index} title={detail.title} />
                  ))}
                </ul>
                <h1 className="text-xl font-bold my-4">You may not</h1>
                <ul className="list-disc list-inside">
                  {youMayNotDetails.map((detail, index) => (
                    <ServiceDetailItem key={index} title={detail.title} />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
