import React, { useState } from "react";
import { TextField } from "@mui/material";
const Amount = ({ row, value, handleAmountChange, confirmUpdate }) => {
  const [editedValue, setEditedValue] = useState(value);
  const handleChange = (e) => {
    setEditedValue(e.target.value);
  };
  const handleBlur = () => {
    if (editedValue !== value) {
      handleAmountChange(row, editedValue);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && editedValue !== value) {
      handleAmountChange(row, editedValue);
    }
  };

  return (
    <TextField
      variant="outlined"
      label="Amount"
      value={editedValue}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      sx={{
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: "none",
          },
        },
        "& .MuiInputBase-input": {
          fontSize: "14px",
        },
      }}
    />
  );
};

export default Amount;
