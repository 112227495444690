import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Dialog, Popover } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { UserButton, SignedIn, SignedOut } from "@clerk/clerk-react";
import logo from "../../assets/images/svg/WPC LOGO.svg";
export default function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { pathname } = useLocation();

  const CustomNavLink = ({ to, children }) => {
    const isActive = pathname === to;

    return (
      <NavLink
        to={to}
        className={`text-md font-Poppins tracking-widest font-semibold leading-6 ${
          isActive ? "text-myBlue" : "text-gray-900"
        }`}
      >
        {children}
      </NavLink>
    );
  };

  return (
    <header className="bg-white ">
      <nav
        className="mx-auto flex md:max-w-4xl lg:max-w-[83rem] items-center justify-between px-6 "
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <NavLink to="/" className="">
            <img
              src={logo}
              alt="Work Permit Consultancy"
              className="w-48 md:w-52"
            />
          </NavLink>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          <CustomNavLink to="/">Home</CustomNavLink>
          <CustomNavLink to="/about-us">About Us</CustomNavLink>
          <CustomNavLink to="/apply-now">Apply Now</CustomNavLink>
          <CustomNavLink to="/dashboard">Dashboard</CustomNavLink>
        </Popover.Group>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <SignedIn>
            <UserButton afterSignOutUrl="/" />
          </SignedIn>
          <SignedIn
            fallback={
              <NavLink
                to="/sign-in"
                className="text-sm font-Poppins font-semibold tracking-widest leading-6  border-2 border-myBlue  hover:border-myBlue hover:bg-white hover:text-myBlue rounded-md px-5 py-2 bg-myBlue text-white"
              >
                Sign in
              </NavLink>
            }
          />
          <SignedOut>
            <NavLink
              to="/sign-in"
              className="text-sm font-Poppins font-semibold tracking-widest leading-6  border-2 border-myBlue  hover:border-myBlue hover:bg-white hover:text-myBlue rounded-md px-5 py-2 bg-myBlue text-white"
            >
              Sign in
            </NavLink>
          </SignedOut>
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <NavLink
              to="/"
              className="-m-1.5 p-1.5 font-semibold text-lg leading-6 font-playfairDisplay  invisible"
            >
              <span className="sr-only">Work Permit Consultancy</span>
              <span className="text-myBlue font-playfairDisplay ">
                Work Permit
              </span>{" "}
              Consultancy
            </NavLink>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="pr-2 sm:py-2">
                <div className=" nav-bar">
                  <img
                    src={logo}
                    alt="Work Permit Consultancy"
                    className="w-48"
                  />
                  <SignedIn>
                    <UserButton afterSignOutUrl="/" />
                  </SignedIn>
                  <SignedIn
                    fallback={
                      <NavLink
                        to="/sign-in"
                        className="text-sm font-Poppins font-semibold tracking-widest leading-6  border-2 border-myBlue  hover:border-myBlue hover:bg-white hover:text-myBlue rounded-md px-3 py-1 bg-myBlue text-white"
                      >
                        Sign in
                      </NavLink>
                    }
                  />
                  <SignedOut>
                    <NavLink
                      to="/sign-in"
                      className="text-sm font-Poppins font-semibold tracking-widest leading-6  border-2 border-myBlue  hover:border-myBlue hover:bg-white hover:text-myBlue rounded-md px-3 py-1 bg-myBlue text-white"
                    >
                      Sign in
                    </NavLink>
                  </SignedOut>
                </div>
              </div>
              <div className="space-y-2 px-3 py-6">
                <NavLink
                  to="/"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-Poppins font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Home
                </NavLink>
                <NavLink
                  to="/about-us"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-Poppins font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  About Us
                </NavLink>
                <NavLink
                  to="/apply-now"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-Poppins font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Apply Now
                </NavLink>
                <NavLink
                  to="/dashboard"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-Poppins font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Dashboard
                </NavLink>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
