import React from "react";
import { FaLinkedinIn, FaFacebookF, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import {
  MdOutlineLocationOn,
  MdOutlineLocalPhone,
  MdOutlineMailOutline,
} from "react-icons/md";
const Footer = () => {
  return (
    <section className="bg-myBlue px-6 lg:px-8">
      <div className="pt-8 pb-4">
        <div className="container max-w-7xl mx-auto flex flex-wrap ">
          {/* Column 1 (4 columns on large screens, 2 columns on medium screens, and full width on small screens) */}
          <div className="w-full md:w-1/2 lg:w-1/4 text-start mb-4 md:mb-0">
            {/* <img src={SeQuenXLogo} alt="logo" className="w-1/3" /> */}
            <h3 className=" text-white text-xl font-playfairDisplay font-semibold mb-4">
              Work Permit <br></br>Consultancy
            </h3>
            <p className="text-gray-400 -mt-3 mb-4 mr-4">
              Our consultancy service blends expertise and personalized support,
              ensuring your journey is efficient and successful. Trust us to
              open doors to new professional opportunities in the Netherlands
            </p>
          </div>
          {/* Column 2 (2 columns on large screens, full width on medium and small screens) */}
          <div className="w-full md:w-1/2 lg:w-1/4 text-start mb-4 md:mb-0">
            <h3 className="text-white text-lg font-semibold">Quick Links</h3>
            <ul className="mt-4">
              <li className="mb-2">
                <a href="/" className="text-gray-400 hover:text-[#ffffff]">
                  Home
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="/apply-now"
                  className="text-gray-400 hover:text-[#ffffff]"
                >
                  Apply Now
                </a>
              </li>

              <li className="mb-2">
                <a
                  href="/about-us"
                  className="text-gray-400 hover:text-[#ffffff]"
                >
                  About Us
                </a>
              </li>
            </ul>
          </div>
          {/* Column 3 (1 column on all screen sizes) */}
          <div className="w-full md:w-1/2 lg:w-1/4 text-start mb-4 md:mb-0">
            <h3 className="text-white text-lg font-semibold">Policies</h3>
            <ul className="mt-4">
              <li className="text-gray-400 mb-2">
                <a
                  href="/information-security"
                  className="text-gray-400 hover:text-[#ffffff]"
                >
                  Information Security
                </a>
              </li>
              <li className="text-gray-400 mb-2">
                <a
                  href="/cookie-policy"
                  className="text-gray-400 hover:text-[#ffffff]"
                >
                  Cookie Policy
                </a>
              </li>
              <li className="text-gray-400 mb-2">
                <a
                  href="/privacy-policy"
                  className="text-gray-400 hover:text-[#ffffff]"
                >
                  Privacy Policy
                </a>
              </li>
              <li className="text-gray-400 mb-2">
                <a
                  href="/terms-and-conditions"
                  className="text-gray-400 hover:text-[#ffffff]"
                >
                  Term & Conditions
                </a>
              </li>
            </ul>
          </div>
          {/* Column 4 (1 column on all screen sizes) */}
          <div className="w-full md:w-1/2 lg:w-1/4 text-start mb-4 md:mb-0">
            <h3 className=" text-white font-semibold mb-4 sm:ml-7">
              Contact Us
            </h3>
            <div className="flex items-center space-x-2 mb-2">
              <MdOutlineLocationOn size={24} color="#dadada" />
              <p className="text-gray-400 w-[90%]">
                Building A2 IFZA Business Park<br></br>
                Dubai Silicon Oasis<br></br>
                Dubai - UAE
              </p>
            </div>
            <div className="flex flex-wrap sm:flex-nowrap items-center space-x-2 mb-2 ">
              <MdOutlineMailOutline size={24} color="#dadada" />
              <p className=" text-gray-400">info@workpermitconsultancy.com</p>
            </div>
            <div className="flex flex-wrap sm:flex-nowrap flex-row space-x-12  mb-4 md:mb-0 hidden">
              <div className="flex flex-wrap sm:flex-nowrap items-center my-2">
                <FaLinkedinIn size={26} color="#f4f4f4" />
              </div>
              <div className="flex items-center my-2 ">
                <FaFacebookF size={26} color="#f4f4f4" />
              </div>
              <div className="flex flex-wrap sm:flex-nowrap items-center hover:  my-2 ">
                <FaXTwitter size={26} color="#f4f4f4" />
              </div>
              <div className="flex flex-wrap sm:flex-nowrap items-center my-2 ">
                <FaInstagram size={26} color="#f4f4f4" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <hr className="border-white mb-4 container max-w-[80.5rem] mx-auto" />
        <div className="md:justify-center container mx-auto md:flex flex-wrap">
          <div className="text-center text-gray-400">
            <p className="pb-2">
              &copy; 2023 - {new Date().getFullYear()} Work Permit Consultancy.
              All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
