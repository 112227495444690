import React from "react";

const LegalHeaderHeader = ({ title }) => {
  return (
    <div className="relative bg-[#f2f7ff] px-4">
      {/* Top blur effect */}
      <div className="absolute top-0 left-0 right-0 h-16 z-0">
        <div className="h-full w-full bg-gradient-to-b from-transparent via-[#f2f7ff] to-[#f2f7ff] blur-sm"></div>
      </div>

      <div className="container max-w-7xl py-20 md:flex md:justify-between md:items-center m-auto relative z-10">
        <h1 className="text-xl font-playfairDisplay font-bold text-gray-800 md:text-3xl">
          {title}
        </h1>
        <div></div>
      </div>

      {/* Bottom blur effect */}
      <div className="absolute bottom-0 left-0 right-0 h-16 z-0">
        <div className="h-full w-full bg-gradient-to-t from-[#f2f7ff] via-[#f2f7ff] to-transparent blur-sm"></div>
      </div>
    </div>
  );
};

export default LegalHeaderHeader;
