"@babel/plugin-proposal-private-property-in-object";
import React, { useState, useEffect, useRef } from "react";
import Business_woman from "./../assets/images/ApplyNow.png";
import FormStep from "./../components/formStep/FormStep";
import { useNavigate } from "react-router-dom";
import { Spinner } from "@material-tailwind/react";

import { Stepper, Step, Button } from "@material-tailwind/react";
import { useLocation } from "react-router-dom";
import {
  useGetBasicQuery,
  useBasicMutation,
  useVerificationMutation,
  useAdditionalMutation,
  useUpdateUserMutation,
} from "../api/api.slice";
import { useUser } from "@clerk/clerk-react";

const ApplyNow = () => {
  useEffect(() => {
    document.title = "Work Permit Consultancy - Apply Now";
  }, []);

  const { state } = useLocation();

  const updateUser = state?.update;
  const [isLoading, setIsLoading] = useState(false);
  const [quitLoading, setQuitLoading] = useState(false);
  const [clerkId, setClerkId] = useState();
  const [updateItem] = useUpdateUserMutation();
  const [postBasicInformation] = useBasicMutation();
  const [postVerification] = useVerificationMutation();
  const [postAdditionalInformation] = useAdditionalMutation();
  const [activeStep, setActiveStep] = useState(0);
  const [shown, setShown] = useState(0);
  const [verificationError, setVerificationError] = useState(null);
  const navigate = useNavigate();
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  // const [id, setId] = useState(0);
  const [fileError, setFileError] = useState({
    passportFileForNew: "",
    passportFileForExtension: "",
    birthCertificate: "",
    antecedentCertificateNew: "",
    birthCertificateOfPartnerOpt: "",
    marriageCertificateOpt: "",
    OtherDocuments: "",
    antecedentCertificateExtension: "",
    OtherDocumentsForExtension: "",
  });
  const { isSignedIn, user, isLoaded } = useUser();
  const {
    data: stepTwoData,
    error: stepTwoError,
    isLoading: stepTwoLoading,
    refetch: stepTwoDataRefetch,
  } = useGetBasicQuery({ clerkId });

  const userRef = useRef(user);
  const [formData, setFormData] = useState({
    step1: {
      termsAndConditions: false,
    },
    step2: {
      firstName: "",
      lastName: "",
      email: "",
      dob: "",
      currentCountry: "",
      currentAddress: "",
      currentPhone: "",
      nationality: "",
      maritalStatus: "",
      requestType: "",
      permitDuration: "",
      caseDescription: "",
    },
    step3: {
      proceed: "",
    },
    step4: {},
  });
  const [error, setError] = useState({
    termsAndConditions: false,
    firstName: false,
    lastName: false,
    email: false,
    dob: false,
    caseDescription: false,
    currentCountry: false,
    currentAddress: false,
    currentPhone: false,
    nationality: false,
    maritalStatus: false,
    requestType: false,
    proceed: false,
    birthCertificate: false,
    passportNumber: false,
    ibanNew: false,
    ibanExtension: false,
    permitDuration: false,
    passportFileForNew: false,
    antecedentCertificateNew: false,
    antecedentCertificateExtension: false,
    passportFileForExtension: false,
    passportNumberForExtension: false,
    bsnNumber: false,
    vNumber: false,
    expiryNLPermitNumber: false,
    currentNLPermitNumber: false,
    emailInvalid: false,
    firstNameInvalid: false,
    lastNameInvalid: false,
  });
  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    if (isLoaded && isSignedIn) {
      // Update the ref when user changes
      userRef.current = user;
      setClerkId(userRef.current.id);
    }
  }, [isLoaded, isSignedIn, user]);

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (!updateUser && !activeStep < 1) {
      stepTwoDataRefetch();
    }
  }, [activeStep]);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    if (formData.step2.requestType === "new") {
      setFormData((prevData) => ({
        ...prevData,
        step4: {
          birthCertificate: null,
          birthCertificateOfPartnerOpt: null,
          marriageCertificateOpt: null,
          passportNumber: "",
          ibanNew: "",
          passportFileForNew: null,
          antecedentCertificateNew: null,
          OtherDocuments: null,
        },
      }));
    }
    if (formData.step2.requestType === "extension") {
      setFormData((prevData) => ({
        ...prevData,
        step4: {
          bsnNumber: "",
          vNumber: "",
          expiryNLPermitNumber: "",
          currentNLPermitNumber: "",
          ibanExtension: "",
          passportNumberForExtension: "",
          antecedentCertificateExtension: null,
          passportFileForExtension: null,
          OtherDocumentsForExtension: null,
        },
      }));
    }
    if (updateUser) {
      if (
        updateUser?.basic_information?.requestType == formData.step2.requestType
      ) {
        if (formData.step2.requestType === "new") {
          setFormData((prevData) => ({
            ...prevData,
            step4: {
              birthCertificate: null,
              birthCertificateOfPartnerOpt: null,
              marriageCertificateOpt: null,
              passportNumber:
                updateUser.additional_Information.new?.passportNumber,
              ibanNew: updateUser.additional_Information.new?.ibanNew,
              passportFileForNew: null,
              OtherDocuments: null,
              antecedentCertificateNew: null,
            },
          }));
        }
        if (formData.step2.requestType === "extension") {
          setFormData((prevData) => ({
            ...prevData,
            step4: {
              bsnNumber: updateUser.additional_Information.extension?.bsnNumber,
              vNumber: updateUser.additional_Information.extension?.vNumber,
              ibanExtension:
                updateUser.additional_Information.extension?.ibanExtension,
              expiryNLPermitNumber:
                updateUser.additional_Information.extension
                  ?.expiryNLPermitNumber,
              currentNLPermitNumber:
                updateUser.additional_Information.extension
                  ?.currentNLPermitNumber,
              passportNumberForExtension:
                updateUser.additional_Information.extension
                  ?.passportNumberForExtension,
              antecedentCertificateExtension: null,
              passportFileForExtension: null,
              OtherDocumentsForExtension: null,
            },
          }));
        }
      } else {
        if (formData.step2.requestType === "new") {
          setFormData((prevData) => ({
            ...prevData,
            step4: {
              birthCertificate: null,
              antecedentCertificateNew: null,
              birthCertificateOfPartnerOpt: null,
              marriageCertificateOpt: null,
              passportNumber: "",
              ibanNew: "",
              passportFileForNew: null,
              OtherDocuments: null,
            },
          }));
        }
        if (formData.step2.requestType === "extension") {
          setFormData((prevData) => ({
            ...prevData,
            step4: {
              bsnNumber: "",
              vNumber: "",
              ibanExtension: "",
              expiryNLPermitNumber: "",
              currentNLPermitNumber: "",
              passportNumberForExtension: "",
              antecedentCertificateExtension: null,
              passportFileForExtension: null,
              OtherDocumentsForExtension: null,
            },
          }));
        }
      }
    }
  }, [formData.step2.requestType]);

  useEffect(() => {
    if (updateUser) {
      // Update form data based on updateUser
      setFormData((prevData) => ({
        ...prevData,
        step1: {
          ...prevData.step1,
          termsAndConditions:
            updateUser.termsAndConditions || prevData.step1.termsAndConditions,
        },
        step2: {
          ...prevData.step2,
          firstName:
            updateUser.basic_information.firstName || prevData.step2.firstName,
          lastName:
            updateUser.basic_information.lastName || prevData.step2.lastName,
          email: updateUser.basic_information.email || prevData.step2.email,
          dob: updateUser.basic_information.dob || prevData.step2.dob,
          currentCountry:
            updateUser.basic_information.currentCountry ||
            prevData.step2.currentCountry,
          currentAddress:
            updateUser.basic_information.currentAddress ||
            prevData.step2.currentAddress,
          currentPhone:
            updateUser.basic_information.currentPhone.toString() ||
            prevData.step2.currentPhone,
          nationality:
            updateUser.basic_information.nationality ||
            prevData.step2.nationality,
          maritalStatus:
            updateUser.basic_information.maritalStatus.toLowerCase() ||
            prevData.step2.maritalStatus,
          requestType:
            updateUser.basic_information.requestType ||
            prevData.step2.requestType,
          permitDuration:
            updateUser.basic_information.permitDuration ||
            prevData.step2.permitDuration,
          caseDescription:
            updateUser.basic_information.caseDescription ||
            prevData.step2.caseDescription,
        },
        step3: {
          ...prevData.step3,
          proceed: updateUser.verification.proceed || prevData.step3.proceed,
        },
        step4: {
          ...prevData.step4,
          bsnNumber:
            updateUser.additional_Information.extension?.bsnNumber ||
            prevData.step4.bsnNumber,
          vNumber:
            updateUser.additional_Information.extension?.vNumber ||
            prevData.step4.vNumber,
          ibanExtension:
            updateUser.additional_Information.extension?.ibanExtension ||
            prevData.step4.ibanExtension,
          currentNLPermitNumber:
            updateUser.additional_Information.extension
              ?.currentNLPermitNumber || prevData.step4.currentNLPermitNumber,
          expiryNLPermitNumber:
            updateUser.additional_Information.extension?.expiryNLPermitNumber ||
            prevData.step4.expiryNLPermitNumber,
          passportNumberForExtension:
            updateUser.additional_Information.extension
              ?.passportNumberForExtension ||
            prevData.step4.passportNumberForExtension,
          passportNumber:
            updateUser.additional_Information.new?.passportNumber ||
            prevData.step4.passportNumber,
          ibanNew:
            updateUser.additional_Information.new?.ibanNew ||
            prevData.step4.ibanNew,
        },
      }));
    }
  }, [updateUser]);

  useEffect(() => {
    if (stepTwoData && !updateUser) {
      // Update form data based on stepTwoData
      setFormData((prevData) => ({
        ...prevData,
        step1: {
          ...prevData.step1,
          termsAndConditions:
            stepTwoData.termsAndConditions || prevData.step1.termsAndConditions,
        },
        step2: {
          ...prevData.step2,
          firstName:
            stepTwoData.basic_information.firstName || prevData.step2.firstName,
          lastName:
            stepTwoData.basic_information.lastName || prevData.step2.lastName,
          email: stepTwoData.basic_information.email || prevData.step2.email,
          dob: stepTwoData.basic_information.dob || prevData.step2.dob,
          currentCountry:
            stepTwoData.basic_information.currentCountry ||
            prevData.step2.currentCountry,
          currentAddress:
            stepTwoData.basic_information.currentAddress ||
            prevData.step2.currentAddress,
          currentPhone:
            stepTwoData.basic_information.currentPhone.toString() ||
            prevData.step2.currentPhone,
          nationality:
            stepTwoData.basic_information.nationality ||
            prevData.step2.nationality,
          maritalStatus:
            stepTwoData.basic_information.maritalStatus.toLowerCase() ||
            prevData.step2.maritalStatus,
          requestType:
            stepTwoData.basic_information.requestType ||
            prevData.step2.requestType,
          permitDuration:
            stepTwoData.basic_information.permitDuration ||
            prevData.step2.permitDuration,
          caseDescription:
            stepTwoData.basic_information.caseDescription ||
            prevData.step2.caseDescription,
        },
        step3: {
          ...prevData.step3,
          proceed: stepTwoData.verification.proceed || prevData.step3.proceed,
        },
        step4: {
          ...prevData.step4,
          bsnNumber:
            stepTwoData.additional_Information.extension?.bsnNumber ||
            prevData.step4.bsnNumber,
          vNumber:
            stepTwoData.additional_Information.extension?.vNumber ||
            prevData.step4.vNumber,
          ibanExtension:
            stepTwoData.additional_Information.extension?.ibanExtension ||
            prevData.step4.ibanExtension,
          currentNLPermitNumber:
            stepTwoData.additional_Information.extension
              ?.currentNLPermitNumber || prevData.step4.currentNLPermitNumber,
          expiryNLPermitNumber:
            stepTwoData.additional_Information.extension
              ?.expiryNLPermitNumber || prevData.step4.expiryNLPermitNumber,
          passportNumberForExtension:
            stepTwoData.additional_Information.extension
              ?.passportNumberForExtension ||
            prevData.step4.passportNumberForExtension,
          passportNumber:
            stepTwoData.additional_Information.new?.passportNumber ||
            prevData.step4.passportNumber,
          ibanNew:
            stepTwoData.additional_Information.new?.ibanNew ||
            prevData.step4.ibanNew,
        },
      }));

      // Set active step based on progress
      if (stepTwoData.basic_information.progress === "pending") {
        setActiveStep(1);
        setShown(1);
      } else if (stepTwoData.verification.progress === "pending") {
        setActiveStep(2);
        setShown(2);
      } else if (stepTwoData.additional_Information.progress === "pending") {
        setActiveStep(3);
        setShown(3);
      }
    }
  }, [stepTwoData, updateUser]);

  const handleCountry = (val, { target: { name, value } }) => {
    validator(name, value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      step2: {
        ...prevFormData.step2,
        currentCountry: val,
      },
    }));
  };

  const handleNationality = (val, { target: { name } }) => {
    validator(name, val);
    setFormData((prevFormData) => ({
      ...prevFormData,
      step2: {
        ...prevFormData.step2,
        nationality: val,
      },
    }));
  };
  const handlePhoneNumberChange = (inputNumber) => {
    if (inputNumber === "") {
      setError((prevError) => ({
        ...prevError,
        currentPhone: true,
      }));
    } else {
      setError((prevError) => ({
        ...prevError,
        currentPhone: false,
      }));
      setFormData((prevFormData) => ({
        ...prevFormData,
        step2: {
          ...prevFormData.step2,
          currentPhone: inputNumber,
        },
      }));
    }
  };
  const validator = (name, value) => {
    if (error[name] !== undefined) {
      // Check for empty value
      if (name === "caseDescription") {
        if (value.trim() === "") {
          setError((prevError) => ({
            ...prevError,
            [name]: false,
          }));
        }
        return false;
      }
      if (value.trim() === "") {
        setError((prevError) => ({
          ...prevError,
          [name]: true,
        }));
        return true;
      }

      if (name === "email") {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          setError((prevError) => ({
            ...prevError,
            [`${name}Invalid`]: true,
          }));
          return true;
        }
      }
      //check for names
      if (name === "firstName" || name === "lastName") {
        const nameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
        if (!nameRegex.test(value.trim())) {
          setError((prevError) => ({
            ...prevError,
            [`${name}Invalid`]: true,
          }));
          return true;
        }
      }
      // Check for BirthCertificate
      if (name === "birthCertificate") {
        if (value === null) {
          if (formData.step4[name]?.length <= 0) {
            setError((prevError) => ({
              ...prevError,
              [name]: true,
            }));
          }
          return true;
        }
      }

      if (
        name === "passportFileForNew" ||
        name === "passportFileForExtension"
      ) {
        if (value === null) {
          if (formData.step4[name]?.length <= 0) {
            setError((prevError) => ({
              ...prevError,
              [name]: true,
            }));
          }
          return true;
        }
      }
      if (
        name === "antecedentCertificateNew" ||
        name === "antecedentCertificateExtension"
      ) {
        if (value === null) {
          if (formData.step4[name]?.length <= 0) {
            setError((prevError) => ({
              ...prevError,
              [name]: true,
            }));
          }
          return true;
        }
      }
      // Reset the error if the value is not empty or doesn't match the email format
      setVerificationError(null);
      setError((prevError) => ({
        ...prevError,
        [name]: false,
        [`${name}Invalid`]: false,
      }));

      return false;
    }
    return false;
  };
  const handleSteps = (event) => {
    const { name, value, files } = event.target;
    if (
      name === "passportFileForNew" ||
      name === "passportFileForExtension" ||
      name === "birthCertificate" ||
      name === "birthCertificateOfPartnerOpt" ||
      name === "marriageCertificateOpt" ||
      name === "antecedentCertificateNew" ||
      name === "antecedentCertificateExtension" ||
      name === "OtherDocuments" ||
      name === "OtherDocumentsForExtension"
    ) {
      const file = files[0];
      const allowedTypes = [
        "application/pdf",
        "image/jpeg",
        "image/jpg",
        "image/png",
      ];
      const maxFileSize = 5 * 1024 * 1024; // 5MB
      let newFileError = { ...fileError };
      // Check file size
      if (file && file.size > maxFileSize) {
        newFileError[name] = "File size should be less than 5MB.";
        setFileError(newFileError);
        setError((prevError) => ({
          ...prevError,
          [name]: false,
        }));
        event.target.value = "";
        return;
      } else {
        newFileError[name] = "";
        setFileError(newFileError);
      }
      // Check file type
      if (file && !allowedTypes.includes(file.type)) {
        newFileError[name] = "Only pdf and image files are allowed";
        setFileError(newFileError);
        setError((prevError) => ({
          ...prevError,
          [name]: false,
        }));
        event.target.value = "";
        return;
      } else {
        newFileError[name] = "";
        setFileError(newFileError);
      }
      if (name === "OtherDocumentsForExtension" || name === "OtherDocuments") {
        const filesArray = Array.from(files);
        if (filesArray && filesArray.length > 0) {
          // const maxFilesSize = 15 * 1024 * 1024;
          // let totalSize = 0;
          filesArray.forEach((file, index) => {
            if (file.size > maxFileSize) {
              newFileError[name] = `File ${
                index + 1
              } size should be less than 5MB.`;
              // totalSize += file.size;
              event.target.value = "";
              return;
            }
            if (!allowedTypes.includes(file.type)) {
              newFileError[
                name
              ] = `Only pdf and image files are allowed for file ${index + 1}.`;
              event.target.value = "";
              return;
            }
          });
          if (filesArray.length > 5) {
            newFileError[name] = `You cannot upload more than 5 files`;
            event.target.value = "";
            return;
          }
          // if (totalSize > maxFilesSize) {
          //   console.log(totalSize, "total and", name);
          //   newFileError[name] = "Total files size should be less than 15MB.";
          // }
          setFileError(newFileError);
        }
      } else {
        newFileError[name] = "";
        setFileError(newFileError);
      }
      setFileError(newFileError);
    }

    validator(name, value);
    setFormData((prevFormData) => {
      const step1Properties = Object.keys(prevFormData.step1);
      const step2Properties = Object.keys(prevFormData.step2);
      const step3Properties = Object.keys(prevFormData.step3);
      const step4Properties = Object.keys(prevFormData.step4);
      return {
        ...prevFormData,
        step1: {
          ...prevFormData.step1,
          ...(step1Properties.includes(name) && {
            [name]: !prevFormData.step1.termsAndConditions,
          }),
        },
        step2: {
          ...prevFormData.step2,
          ...(step2Properties.includes(name) && { [name]: value }),
        },
        step3: {
          ...prevFormData.step3,
          ...(step3Properties.includes(name) && { [name]: value }),
        },

        step4: {
          ...prevFormData.step4,
          ...(step4Properties.includes(name) && {
            [name]: files ? files : value,
          }),
        },
      };
    });
  };
  const handleSubmit = async (e, activeStep, isSaveAndQuit = false) => {
    e.preventDefault();
    if (isSaveAndQuit) {
      setIsModalOpen(true);
      if (isLastStep) {
        setModalContent({
          title: "Thank you for Choosing Work Permit Consultancy",
          message:
            "You can now quit the form. We have received your application and will begin processing it promptly. If you need to update your information, please note that you can do so only once. Feel free to close this window",
          action: () => {
            setIsModalOpen(false);
            setQuitLoading(true);
            setTimeout(() => {
              navigate("/");
              window.scrollTo(0, 0);
            }, 2000);
            return;
          },
        });
      } else {
        setModalContent({
          title: "Are you sure you want to quit?",
          message: "Exiting now will not save the changes on the current step",
          action: () => {
            setIsModalOpen(false);
            setQuitLoading(true);
            setTimeout(() => {
              navigate("/");
              window.scrollTo(0, 0);
            }, 2000);
            return;
          },
        });
      }

      return;
    }
    const activeStepFields = formData[`step${activeStep + 1}`];
    const updatedErrors = {};
    for (const fieldKey in activeStepFields) {
      const fieldValue = activeStepFields[fieldKey];
      if (
        fieldKey === "birthCertificate" ||
        fieldKey === "passportFileForNew" ||
        fieldKey === "passportFileForExtension" ||
        fieldKey === "antecedentCertificateNew" ||
        fieldKey === "antecedentCertificateExtension"
      ) {
        if (fieldValue === null) {
          updatedErrors[fieldKey] = fieldValue == null;
        }
      } else if (fieldKey === "currentPhone") {
        if (fieldValue <= 5) {
          updatedErrors[fieldKey] = fieldValue.length <= 5;
        }
      } else if (fieldKey === "caseDescription") {
        updatedErrors[fieldKey] = false;
      } else {
        updatedErrors[fieldKey] = fieldValue === "";
      }
    }
    setError((prevError) => ({
      ...prevError,
      ...updatedErrors,
    }));
    if (Object.values(updatedErrors).some((value) => value === true)) {
      return;
    }
    if (Object.values(error).some((value) => value === true)) {
      return;
    }
    const requiredEmptyFields = [
      "passportFileForNew",
      "passportFileForExtension",
      "birthCertificate",
      "antecedentCertificateNew",
      "antecedentCertificateExtension",
    ];
    if (
      Object.keys(fileError).some(
        (key) => requiredEmptyFields.includes(key) && fileError[key] !== ""
      )
    ) {
      return;
    }
    if (updateUser) {
      try {
        setIsLoading(true);
        const updateData = {
          basic_information: formData.step2,
          additional_Information: formData.step4,
        };
        if (activeStep === 3) {
          const formDocuments = new FormData();
          if (formData.step2.requestType === "new") {
            formDocuments.append(
              "passportNumber",
              formData.step4.passportNumber
            );
            formDocuments.append("ibanNew", formData.step4.ibanNew);
            formDocuments.append(
              "birthCertificate",
              formData.step4.birthCertificate[0]
            );
            formDocuments.append(
              "passportFileForNew",
              formData.step4.passportFileForNew[0]
            );
            formDocuments.append(
              "antecedentCertificateNew",
              formData.step4.antecedentCertificateNew[0]
            );

            if (
              formData.step4.marriageCertificateOpt &&
              formData.step4.marriageCertificateOpt[0]
            ) {
              formDocuments.append(
                "marriageCertificateOpt",
                formData.step4.marriageCertificateOpt[0]
              );
            }

            if (
              formData.step4.birthCertificateOfPartnerOpt &&
              formData.step4.birthCertificateOfPartnerOpt[0]
            ) {
              formDocuments.append(
                "birthCertificateOfPartnerOpt",
                formData.step4.birthCertificateOfPartnerOpt[0]
              );
            }
            if (
              formData.step4.OtherDocuments &&
              formData.step4.OtherDocuments[0]
            ) {
              for (let i = 0; i < formData.step4.OtherDocuments.length; i++) {
                formDocuments.append(
                  `OtherDocuments`,
                  formData.step4.OtherDocuments[i]
                );
              }
            }
          }
          if (formData.step2.requestType === "extension") {
            formDocuments.append("bsnNumber", formData.step4.bsnNumber);
            formDocuments.append("vNumber", formData.step4.vNumber);
            formDocuments.append("ibanExtension", formData.step4.ibanExtension);
            formDocuments.append(
              "expiryNLPermitNumber",
              formData.step4.expiryNLPermitNumber
            );
            formDocuments.append(
              "currentNLPermitNumber",
              formData.step4.currentNLPermitNumber
            );
            formDocuments.append(
              "passportNumberForExtension",
              formData.step4.passportNumberForExtension
            );
            formDocuments.append(
              "passportFileForExtension",
              formData.step4.passportFileForExtension[0]
            );
            formDocuments.append(
              "antecedentCertificateExtension",
              formData.step4.antecedentCertificateExtension[0]
            );
            if (
              formData.step4.OtherDocumentsForExtension &&
              formData.step4.OtherDocumentsForExtension[0]
            ) {
              for (
                let i = 0;
                i < formData.step4.OtherDocumentsForExtension.length;
                i++
              ) {
                formDocuments.append(
                  `OtherDocumentsForExtension`,
                  formData.step4.OtherDocumentsForExtension[i]
                );
              }
            }
          }
          formDocuments.append("clerkId", clerkId);
          formDocuments.append("_id", updateUser._id);
          formDocuments.append("updateRequestType", formData.step2.requestType);
          formDocuments.append("progress", "completed");
          formDocuments.append("status", "updated");
          formDocuments.append("update", true);

          // Use your updateItem function to update the user data along with documents
          await updateItem({
            id: updateUser._id,
            fileStatus: "updated",
            body: formDocuments,
          });
        } else {
          // For other steps, only update basic and additional information
          await updateItem({
            id: updateUser._id,
            fileStatus: "updated",
            body: updateData,
          });
        }
      } catch (error) {
        // Handle the error as needed
      } finally {
        setIsLoading(false);
        handleNext();
      }
    } else {
      try {
        setIsLoading(true);

        if (activeStep === 0) {
          handleNext();
        }
        // Post data from step 2
        if (activeStep === 1) {
          const basicInformationResponse = await postBasicInformation({
            body: {
              ...formData.step2,
              clerkId,
              progress: "completed",
              status: "pending",
              termsAndConditions: formData.step1.termsAndConditions,
            },
          });

          localStorage.setItem("id", JSON.stringify(basicInformationResponse));
          // setId(basicInformationResponse);

          // Check if postBasicInformation was successful
          if (basicInformationResponse.error) {
            // Handle the error as needed
            return;
          } else {
            handleNext();
          }
        }
        // Post data from step 3
        if (activeStep === 2) {
          const storedResponse = localStorage.getItem("id");
          const id = JSON.parse(storedResponse);

          const verificationResponse = await postVerification({
            body: {
              ...formData.step3,
              _id: id.data,
              clerkId,
              progress: "completed",
            },
          });
          if (verificationResponse.error) {
            setVerificationError(verificationResponse.error.data);
            return;
          }

          handleNext();
        }
        if (activeStep === 3) {
          const storedResponse = localStorage.getItem("id");
          const id = JSON.parse(storedResponse);
          // Post data from step 4
          const formDocuments = new FormData();

          // Append common data to FormData
          if (formData.step2.requestType === "new") {
            formDocuments.append(
              "passportNumber",
              formData.step4.passportNumber
            );
            formDocuments.append("ibanNew", formData.step4.ibanNew);
            formDocuments.append(
              "birthCertificate",
              formData.step4.birthCertificate[0]
            );
            formDocuments.append(
              "antecedentCertificateNew",
              formData.step4.antecedentCertificateNew[0]
            );
            formDocuments.append(
              "passportFileForNew",
              formData.step4.passportFileForNew[0]
            );
            if (
              formData.step4.marriageCertificateOpt &&
              formData.step4.marriageCertificateOpt[0]
            ) {
              formDocuments.append(
                "marriageCertificateOpt",
                formData.step4.marriageCertificateOpt[0]
              );
            }

            if (
              formData.step4.birthCertificateOfPartnerOpt &&
              formData.step4.birthCertificateOfPartnerOpt[0]
            ) {
              formDocuments.append(
                "birthCertificateOfPartnerOpt",
                formData.step4.birthCertificateOfPartnerOpt[0]
              );
            }
            if (
              formData.step4.OtherDocuments &&
              formData.step4.OtherDocuments[0]
            ) {
              for (let i = 0; i < formData.step4.OtherDocuments.length; i++) {
                formDocuments.append(
                  `OtherDocuments`,
                  formData.step4.OtherDocuments[i]
                );
              }
            }
          }
          if (formData.step2.requestType === "extension") {
            formDocuments.append("bsnNumber", formData.step4.bsnNumber);
            formDocuments.append("vNumber", formData.step4.vNumber);
            formDocuments.append(
              "expiryNLPermitNumber",
              formData.step4.expiryNLPermitNumber
            );
            formDocuments.append("ibanExtension", formData.step4.ibanExtension);
            formDocuments.append(
              "currentNLPermitNumber",
              formData.step4.currentNLPermitNumber
            );
            formDocuments.append(
              "passportNumberForExtension",
              formData.step4.passportNumberForExtension
            );
            formDocuments.append(
              "passportFileForExtension",
              formData.step4.passportFileForExtension[0]
            );
            formDocuments.append(
              "antecedentCertificateExtension",
              formData.step4.antecedentCertificateExtension[0]
            );
            if (
              formData.step4.OtherDocumentsForExtension &&
              formData.step4.OtherDocumentsForExtension[0]
            ) {
              for (
                let i = 0;
                i < formData.step4.OtherDocumentsForExtension.length;
                i++
              ) {
                formDocuments.append(
                  `OtherDocumentsForExtension`,
                  formData.step4.OtherDocumentsForExtension[i]
                );
              }
            }
          }
          formDocuments.append("clerkId", clerkId);
          formDocuments.append("_id", id.data);
          formDocuments.append("progress", "completed");
          formDocuments.append("status", "completed");

          const additionalInformationResponse = await postAdditionalInformation(
            { id: id.data, body: formDocuments }
          );

          // Check if postAdditionalInformation was successful
          if (additionalInformationResponse.error) {
            // Handle the error as needed
            return;
          }
          handleNext();
        }
      } catch (error) {
        // Handle the error as needed
      } finally {
        setIsLoading(false);
      }
    }
  };
  const handleNext = () => !isLastStep && setActiveStep((cur) => cur + 1);
  const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);

  return (
    <div>
      <div className="p-4">
        <div className="lg:max-w-[82rem] mx-auto w-full md:w-[80%] flex flex-col md:flex-row md:flex-wrap justify-between">
          <div className=" px-1 py-[9%] md:w-[45%] mb-4 md:mb-0">
            <h1 className="font-playfairDisplay tracking-wide  text-2xl font-extrabold mb-4 text-myBlue w-[100%]">
              Forge Your <br></br> Professional Path
            </h1>
            <p className="font-Poppins text-lg mb-6 ">
              your portal to new horizons. Unleash your potential with our
              streamlined application process, expert guidance, and a commitment
              to making your work permit journey seamless
            </p>
          </div>

          <div className=" md:w-[45%] relative h-[400px]">
            <div className="absolute -z-10 top-[50%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center">
              <div className="w-[300px] h-[300px] -top-[172px] bg-[#f2f7ff] bg-opacity-95  rounded-full absolute"></div>
              <div className="w-[350px] h-[350px] bg-[#eaf2ff] bg-opacity-40 rounded-full absolute"></div>
            </div>

            <img
              src={Business_woman}
              alt="work permit consultancy"
              className="lg:w-[60%] h-[100%] object-cover mx-auto"
            />
          </div>
        </div>
      </div>
      <div className="p-4 bg-[#f2f7ff]  mx-auto">
        <div className="container w-[100%] sm:w-[80%] mx-auto bg-white rounded-xl">
          <form onSubmit={handleSubmit} enc-type="multipart/form-data">
            <div className="w-full py-4 px-8">
              <Stepper
                className="w-[100%] sm:w-[80%] mx-auto"
                activeStep={activeStep}
                activeLineClassName="!bg-myBlue text-white "
                isLastStep={(value) => setIsLastStep(value)}
                isFirstStep={(value) => setIsFirstStep(value)}
              >
                <Step
                  activeClassName="!bg-myBlue text-white"
                  completedClassName="!bg-myBlue text-white"
                  // onClick={updateUser ? () => setActiveStep(0) : undefined}
                >
                  1
                </Step>
                <Step
                  activeClassName="!bg-myBlue text-white"
                  completedClassName="!bg-myBlue text-white"
                  // onClick={updateUser ? () => setActiveStep(1) : undefined}
                >
                  2
                </Step>
                <Step
                  activeClassName="!bg-myBlue text-white"
                  completedClassName="!bg-myBlue text-white"
                  // onClick={updateUser ? () => setActiveStep(2) : undefined}
                >
                  3
                </Step>
                <Step
                  activeClassName="!bg-myBlue text-white"
                  completedClassName="!bg-myBlue text-white"
                  // onClick={updateUser ? () => setActiveStep(3) : undefined}
                >
                  4
                </Step>
                <Step
                  activeClassName="!bg-myBlue text-white"
                  completedClassName="!bg-myBlue text-white"
                  // onClick={updateUser ? () => setActiveStep(4) : undefined}
                >
                  5
                </Step>
              </Stepper>
              <div className="mt-16 w-full gap-1">
                <FormStep
                  step={activeStep}
                  handleSteps={handleSteps}
                  FormData={formData}
                  Error={error}
                  handlePhoneNumberChange={handlePhoneNumberChange}
                  handleCountry={handleCountry}
                  handleNationality={handleNationality}
                  shown
                  verificationError={verificationError}
                  fileError={fileError}
                />
              </div>
              <div className="mt-16 flex sm:flex-nowrap items-center justify-center gap-1">
                <div className="flex items-center">
                  <Button
                    type="submit"
                    className="md:px-10 !bg-myBlue text-white flex items-center"
                    onClick={(e) => handleSubmit(e, activeStep, true)}
                    disabled={isFirstStep}
                  >
                    {quitLoading ? (
                      <>
                        {" "}
                        <Spinner
                          className="mr-2 h-4 w-4 text-myBlue "
                          color="white"
                        />{" "}
                      </>
                    ) : (
                      ""
                    )}
                    Quit
                  </Button>
                </div>
                {!isLastStep && (
                  <>
                    {isLoading ? (
                      <div className="flex items-center">
                        <Button
                          type="submit"
                          className="bg-myBlue text-white mr-2 flex items-center"
                          onClick={(e) => handleSubmit(e, activeStep, false)}
                        >
                          <Spinner
                            className="mr-2 h-4 w-4 text-myBlue"
                            color="white"
                          />{" "}
                          Submit
                        </Button>
                      </div>
                    ) : (
                      <Button
                        type="submit"
                        className="!bg-myBlue text-white"
                        onClick={(e) => handleSubmit(e, activeStep, false)}
                        disabled={
                          !formData.step1.termsAndConditions ||
                          (activeStep === 1 &&
                            !updateUser &&
                            stepTwoData?.basic_information.progress ===
                              "completed") ||
                          (activeStep === 2 &&
                            !updateUser &&
                            stepTwoData?.verification.progress ===
                              "completed") ||
                          (activeStep === 3 &&
                            !updateUser &&
                            stepTwoData?.additional_Information.progress ===
                              "completed")
                        }
                      >
                        Submit
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      {isModalOpen && (
        <div
          id="popup-modal"
          className="fixed top-0 left-0 right-0 bottom-0 z-50 flex justify-center items-center bg-gray-700 bg-opacity-50"
        >
          <div className="relative p-4 w-full max-w-md max-h-full">
            <div className="relative bg-white rounded-lg shadow">
              <button
                type="button"
                onClick={closeModal}
                className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
              >
                {/* Close icon */}
              </button>
              <div className="p-4 md:p-5 text-center">
                <h2 className="mb-5 text-lg font-bold font-Roboto text-[#094b72] underline">
                  {modalContent.title}
                </h2>
                <p className="mb-5 px-2">{modalContent.message}</p>
                <button
                  onClick={modalContent.action}
                  className="bg-[#094b72] text-white px-5 py-2 rounded-lg text-sm"
                >
                  Confirm
                </button>
                <button
                  onClick={closeModal}
                  className="ml-3 px-5 py-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApplyNow;
