"@babel/plugin-proposal-private-property-in-object";
import React, { useState, useRef, useEffect } from "react";
import { MdModeEdit } from "react-icons/md";
import axios from "axios";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { IoMdAddCircle } from "react-icons/io";
import { IoEye } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Spinner } from "@material-tailwind/react";
import Box from "@mui/material/Box";
import { ImCross } from "react-icons/im";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Amount from "../components/Amount";
import {
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

import {
  useGetAllQuery,
  useCheckStatusMutation,
  useGetUserQuery,
  useAssignTokenMutation,
  useDeleteUserMutation,
  useTokenEmailMutation,
  useUpdateUserPartialMutation,
} from "../api/api.slice";
import { useUser } from "@clerk/clerk-react";
const VISIBLE_FIELDS = [
  "firstName",
  "lastName",
  "email",
  "verificationToken",
  "status",
];
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
}

export default function Dashboard() {
  useEffect(() => {
    document.title = "Work Permit Consultancy - Dashboard";
  }, []);

  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const { isSignedIn, user, isLoaded } = useUser();
  const [clerkId, setClerkId] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [viewModalContent, setViewModalContent] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState("");

  const userRef = useRef(user);
  const [checkAdminStatus] = useCheckStatusMutation();
  const [checkToken] = useAssignTokenMutation();
  const [sendTokenEmail] = useTokenEmailMutation();
  const [updateUserPartial] = useUpdateUserPartialMutation();
  const [deleteUser] = useDeleteUserMutation();

  useEffect(() => {
    const fetchData = async () => {
      if (isLoaded && isSignedIn) {
        userRef.current = user;
        const clerkId = userRef.current.id;

        try {
          // Use the mutate function to send the request
          const response = await checkAdminStatus(clerkId);

          // Check if response.data exists before accessing isAdmin property
          const isAdmin = response.data && response.data.isAdmin;

          setIsAdmin(isAdmin || false);
          setClerkId(clerkId);
        } catch (error) {
          console.error("Error checking admin status:", error);
        }
      }
    };

    fetchData();
  }, []);
  const {
    data: adminData,
    // error: adminError,
    isLoading: adminLoading,
  } = useGetAllQuery(clerkId);
  const {
    data: userData,
    // error: userError,
    isLoading: userLoading,
  } = useGetUserQuery({ clerkId, route: "/dashboard/user" });
  if (adminLoading || userLoading) {
    return (
      <div className="flex items-center justify-center h-[60vh]">
        <Spinner className="h-11 w-11" color="indigo" />
      </div>
    );
  }

  let Alldata;
  if (isAdmin) {
    Alldata = adminData;
  } else {
    Alldata = userData;
  }

  const handleDelete = (item) => {
    const user = Alldata.data?.find((entry) => entry._id === item.id);
    setModalContent({
      title: "Delete User",
      message: `Please Confirm you want to delete application of ${user.basic_information?.firstName} ${user.basic_information?.lastName}`,
      action: () => confirmDelete(user._id),
    });
    setIsModalOpen(true);
  };

  const confirmDelete = async (id) => {
    try {
      await deleteUser(id);
      setIsModalOpen(false);
      window.location.reload();
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };
  const handleViewFile = async (path) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}serve/`,
        // `http://localhost:4000/serve/`,
        {
          params: {
            filepath: `${path}`,
          },
          responseType: "arraybuffer",
        }
      );
      let mimeType;
      if (path.endsWith(".pdf")) {
        mimeType = "application/pdf";
      } else if (path.endsWith(".jpeg") || path.endsWith(".jpg")) {
        mimeType = "image/jpeg";
      } else if (path.endsWith(".png")) {
        mimeType = "image/png";
      } else {
        console.error("Unsupported file type");
        return;
      }

      const blob = new Blob([response.data], { type: mimeType });
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error:", error.message);
    }
  };
  const handleButtonClick = (item) => {
    const user = Alldata.data?.find((entry) => entry._id === item.id);
    setModalContent({
      title: "Token Generation",
      message: `Please Confirm You want to generate a token for ${user.basic_information?.firstName} ${user.basic_information?.lastName}`,
      action: () => generateToken(user._id),
    });
    setIsModalOpen(true);
  };

  const handleTokenEmail = (item) => {
    const user = Alldata.data?.find((entry) => entry._id === item.id);
    setModalContent({
      title: "Send Token To User",
      message: `Please confirm you want to send verification token to ${user.basic_information?.firstName} ${user.basic_information?.lastName}`,
      action: () => confirmSendTokenEmail(user),
      isLoading: false,
    });
    setIsModalOpen(true);
  };

  const confirmSendTokenEmail = async (item) => {
    try {
      setModalContent((prevState) => ({
        ...prevState,
        isLoading: true, // Show loading state
        message: `Sending verification token to ${item.basic_information?.firstName} ${item.basic_information?.lastName}...`,
      }));

      await sendTokenEmail({ clerkId, id: item._id });
      setIsModalOpen(false);
      window.location.reload();
    } catch (error) {
      console.error("Error sending token email:", error);
      // Handle error feedback if necessary
    }
  };

  const generateToken = async (id) => {
    await checkToken({ clerkId, id });
    setIsModalOpen(false);
    window.location.reload();
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setViewModalOpen(false);
  };
  const handlePreview = (item) => {
    const user = Alldata.data?.find((entry) => entry._id === item);
    setViewModalOpen(true);
    setViewModalContent(user);
    // navigate("/apply-now", { state: { props: user } });
  };

  const handleUpdate = (item) => {
    const user = Alldata.data?.find((entry) => entry._id === item.id);
    navigate("/apply-now", { state: { update: user } });
  };

  const handleAddNew = () => {
    navigate("/apply-now");
  };

  //Amount And Status Handlers
  const handleStatusChange = (row, e) => {
    const updatedStatus = e.target.value; // Get new status value
    const userId = row.id; // Get the user ID
    setModalContent({
      title: "Update Status",
      message: `Are you sure you want to update the status to "${updatedStatus}" for ${row.firstName} ${row.lastName}?`,
      action: () =>
        confirmUpdate({
          id: userId,
          body: { applicationStatus: updatedStatus },
        }),
    });
    setIsModalOpen(true); // Show modal
  };

  const handleAmountChange = (row, val) => {
    const updatedAmount = val; // Get new amount value
    const userId = row.id; // Get the user ID
    setModalContent({
      title: "Update Amount",
      message: `Are you sure you want to update the amount to "${updatedAmount}" for ${row.firstName} ${row.lastName}?`,
      action: () =>
        confirmUpdate({ id: userId, body: { amount: updatedAmount } }),
    });
    setIsModalOpen(true); // Show modal
  };
  const confirmUpdate = async ({ id, body }) => {
    try {
      await updateUserPartial({ clerkId, id, body }); // Call RTK Query mutation
      setIsModalOpen(false); // Close modal
      window.location.reload(); // Reload the page to reflect changes
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const adminCols = [
    {
      field: "firstName",
      headerName: "First Name",
      minWidth: 150,
      maxWidth: 300,
      headerClassName: "columnHeader",
    },
    {
      field: "lastName",
      headerName: "Last Name",
      minWidth: 150,
      maxWidth: 300,
      headerClassName: "columnHeader",
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 150,
      flex: 0.5,
      headerClassName: "columnHeader",
    },
    {
      field: "verificationToken",
      headerName: "Token",
      minWidth: 150,
      flex: 0.5,
      headerClassName: "columnHeader",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",

            alignItems: "center",
            height: "100%",
          }}
        >
          {!params.row.verificationToken ? (
            <button
              onClick={() => handleButtonClick(params.row)}
              className="bg-myBlue hover:bg-transparent text-white hover:outline hover:outline-[2px] hover:outline-myBlue hover:text-myBlue px-2 py-1 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600 flex items-center justify-center h-8"
            >
              Generate
            </button>
          ) : (
            <p>{params.row.verificationToken}</p>
          )}
        </div>
      ),
    },
    {
      field: "sentEmail",
      headerName: "Send Token",
      flex: 0.5,
      headerClassName: "columnHeader",
      minWidth: 150,
      maxWidth: 150,

      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          {!params.row.verificationToken ? (
            <button
              onClick={() => handleTokenEmail(params.row)}
              disabled={true}
              className="bg-[#909090]  text-white text-sm px-[5px] rounded "
            >
              Send
            </button>
          ) : params.row.sentEmail === "not-sent" ? (
            <button
              onClick={() => handleTokenEmail(params.row)}
              className="bg-myBlue text-sm px-[5px]  hover:bg-transparent text-white hover:outline hover:outline-[2px] hover:outline-myBlue hover:text-myBlue  rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            >
              Send
            </button>
          ) : (
            <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
              {params.row.sentEmail}
            </span>
          )}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      minWidth: 150,
      maxWidth: 150,

      headerClassName: "columnHeader",
      renderCell: (params) => (
        <div className="w-full max-w-sm ">
          <div className="relative">
            <select
              className="w-full bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded pl-3 pr-8 py-1.5 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md appearance-none cursor-pointer"
              value={params.row.status}
              onChange={(e) => handleStatusChange(params.row, e)}
            >
              <option value="pending">In-Progress</option>
              <option value="updated">Updated</option>
              <option value="completed">Completed</option>
              <option value="issued">Issued</option>
              <option value="rejected">Rejected</option>
              <option value="approved">Approved</option>
            </select>
            <div className="reletive my-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.2"
                stroke="currentColor"
                className="h-5 w-5 ml-1 absolute top-3 right-2.5 text-slate-700"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                />
              </svg>
            </div>
          </div>
        </div>
      ),
    },
    {
      field: "view",
      headerName: "View",
      flex: 0.5,
      sortable: false,
      minWidth: 100,
      maxWidth: 100,

      headerClassName: "columnHeader",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          <button
            onClick={() => handlePreview(params.row.id)}
            className="bg-transparent hover:text-blue-500 text-myBlue font-semibold  hover:border-transparent rounded"
          >
            <IoEye size={25} />
          </button>
        </div>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,

      flex: 0.5,
      minWidth: 100,
      maxWidth: 100,
      headerClassName: "columnHeader",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          <button
            onClick={() => handleDelete(params.row)}
            className="bg-transparent hover:text-red-500 text-myBlue font-semibold  hover:border-transparent rounded"
          >
            <RiDeleteBin6Fill size={24} />
          </button>
        </div>
      ),
    },
    {
      field: "amount",
      headerName: "Paid Amount",
      flex: 0.5,
      minWidth: 200,
      headerClassName: "columnHeader",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Amount
            row={params.row} // Pass the row object to the child component
            value={params?.row?.amount} // Pass the current amount as value
            handleAmountChange={handleAmountChange} // Pass handler for amount change
            confirmUpdate={confirmUpdate} // Pass the confirmUpdate function
          />
        </div>
      ),
    },
  ];

  const adminRows =
    Alldata?.data?.map((item, index) => ({
      id: item._id, // DataGrid requires an `id` field
      firstName: item?.basic_information?.firstName,
      lastName: item?.basic_information?.lastName,
      email: item?.basic_information?.email,
      verificationToken: item?.verificationToken,
      sentEmail: item?.sentEmail,
      status: item?.status,
      amount: item?.amount,
    })) || [];

  const userRows =
    Alldata?.data?.map((item, index) => ({
      id: item._id,
      firstName: item?.basic_information?.firstName,
      lastName: item?.basic_information?.lastName,
      email: item?.basic_information?.email,
      status: item?.status,
    })) || [];
  const userCols = [
    {
      field: "firstName",
      headerClassName: "columnHeader",
      headerName: "First Name",
      flex: 0.5,
      minWidth: 150,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 0.5,
      minWidth: 150,

      headerClassName: "columnHeader",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.5,
      minWidth: 150,
      headerClassName: "columnHeader",
    },

    {
      field: "view",
      headerName: "View",
      flex: 0.5,
      minWidth: 120,
      maxWidth: 120,
      sortable: false,
      headerClassName: "columnHeader",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          <button
            onClick={() => handlePreview(params.row.id)}
            className="bg-transparent hover:text-blue-500 text-myBlue font-semibold  hover:border-transparent rounded"
          >
            <IoEye size={25} />
          </button>
        </div>
      ),
    },
    {
      field: "edit",
      headerName: "Edit",
      flex: 0.5,
      minWidth: 120,
      maxWidth: 120,
      sortable: false,
      headerClassName: "columnHeader",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          <button
            onClick={() => handleUpdate(params.row)}
            disabled={
              params?.row?.status === "updated" ||
              params?.row?.status === "approved" ||
              params?.row?.status === "rejected" ||
              params?.row?.status === "issued"
            }
            className={
              params?.row?.status === "updated" ||
              params?.row?.status === "approved" ||
              params?.row?.status === "rejected" ||
              params?.row?.status === "issued"
                ? "text-[#808080]"
                : "bg-transparent hover:text-blue-500 text-myBlue font-semibold hover:border-transparent rounded"
            }
          >
            <MdModeEdit size={25} />
          </button>
        </div>
      ),
    },

    {
      field: "delete",
      headerName: "Delete",
      flex: 0.5,
      minWidth: 120,
      maxWidth: 120,
      sortable: false,
      headerClassName: "columnHeader",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          <button
            disabled={params.row.status !== "pending"}
            onClick={() => handleDelete(params.row)}
            className={
              params.row.status !== "pending"
                ? "text-[#808080]"
                : "bg-transparent hover:text-red-500 text-myBlue font-semibold  hover:border-transparent rounded"
            }
          >
            <RiDeleteBin6Fill size={24} />
          </button>
        </div>
      ),
    },
  ];
  return (
    <div>
      {isAdmin ? (
        <>
          <header className="bg-myBlue text-white text-center py-8">
            <h1 className="text-2xl font-bold font-playfairDisplay capitalize">
              Welcome Admin {user.firstName}
            </h1>
          </header>
          <main className="container mx-auto mt-8 p-4 min-h-[50vh]">
            <h2 className="my-2 font-Roboto font-semibold text-2xl text-myBlue">
              Applications
            </h2>
            <section className="my-3">
              <Box sx={{ height: 400, width: "100%" }}>
                <DataGrid
                  rows={adminRows}
                  rowHeight={45}
                  columns={adminCols}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        amount: false,
                      },
                    },
                    filter: {
                      filterModel: {
                        items: [],
                      },
                    },
                  }}
                  disableDensitySelector
                  slots={{ toolbar: CustomToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                  pageSize={10}
                  rowsPerPageOptions={[10, 25, 50]}
                />
              </Box>
            </section>
          </main>
        </>
      ) : (
        <>
          <header className="bg-myBlue text-white text-center py-8">
            <h1 className="text-2xl font-bold font-playfairDisplay capitalize">
              Welcome {user.fullName}
            </h1>
          </header>
          <main className="container mx-auto mt-8 p-4 min-h-[50vh] ">
            <div className="flex gap-4 items-center content-center">
              <h2 className="my-2 font-Roboto font-semibold text-2xl text-myBlue">
                Your Applications
              </h2>
              <button onClick={handleAddNew}>
                <IoMdAddCircle size={25} color={"#094b72"} />
              </button>
            </div>
            <section className="my-3">
              {Alldata?.data && Alldata?.count > 0 ? (
                <Box sx={{ height: 400, width: "100%" }}>
                  <DataGrid
                    rows={userRows}
                    rowHeight={45}
                    columns={userCols}
                    initialState={{
                      pagination: {
                        pageSize: 10,
                      },
                      filter: {
                        filterModel: {
                          items: [],
                        },
                      },
                    }}
                    disableDensitySelector
                    slots={{ toolbar: CustomToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                    pageSize={10}
                    rowsPerPageOptions={[10, 25, 50]}
                  />
                </Box>
              ) : (
                <p>Please submit an application to view here </p>
              )}
            </section>
          </main>
        </>
      )}
      {viewModalOpen && (
        <div
          id="popup-modal"
          className="fixed inset-0 z-50 flex justify-center items-center bg-gray-700 bg-opacity-50"
        >
          <div className="relative p-4 w-full max-w-[85%] mx-auto max-h-full overflow-y-auto no-scrollbar">
            <div className="relative bg-white rounded-lg shadow-lg">
              <button
                type="button"
                onClick={closeModal}
                className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
              >
                <ImCross />
              </button>
              <div className="p-6 overflow-y-auto max-h-[95vh] no-scrollbar">
                <h2 className="text-2xl font-bold font-Roboto text-[#094b72] underline mb-6">
                  Basic Information
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-6 bg-gray-50 p-4 rounded-lg shadow">
                  <div className="space-y-2">
                    <p className="font-semibold text-lg">First Name:</p>
                    <p className="text-gray-700">
                      {viewModalContent?.basic_information?.firstName || "N/A"}
                    </p>
                  </div>
                  <div className="space-y-2">
                    <p className="font-semibold text-lg">Last Name:</p>
                    <p className="text-gray-700">
                      {viewModalContent?.basic_information?.lastName || "N/A"}
                    </p>
                  </div>
                  <div className="space-y-2">
                    <p className="font-semibold text-lg">Email Address:</p>
                    <p className="text-gray-700">
                      {viewModalContent?.basic_information?.email || "N/A"}
                    </p>
                  </div>
                  <div className="space-y-2">
                    <p className="font-semibold text-lg">Date of Birth:</p>
                    <p className="text-gray-700">
                      {viewModalContent?.basic_information?.dob || "N/A"}
                    </p>
                  </div>
                  <div className="space-y-2">
                    <p className="font-semibold text-lg">Country:</p>
                    <p className="text-gray-700">
                      {viewModalContent?.basic_information?.currentCountry ||
                        "N/A"}
                    </p>
                  </div>
                  <div className="space-y-2">
                    <p className="font-semibold text-lg">Nationality:</p>
                    <p className="text-gray-700">
                      {viewModalContent?.basic_information?.nationality ||
                        "N/A"}
                    </p>
                  </div>
                  <div className="space-y-2">
                    <p className="font-semibold text-lg">Address:</p>
                    <p className="text-gray-700">
                      {viewModalContent?.basic_information?.currentAddress ||
                        "N/A"}
                    </p>
                  </div>
                  <div className="space-y-2">
                    <p className="font-semibold text-lg">Phone:</p>
                    <p className="text-gray-700">
                      {viewModalContent?.basic_information?.currentPhone ||
                        "N/A"}
                    </p>
                  </div>
                </div>

                <h3 className=" text-2xl font-bold font-Roboto text-[#094b72] underline mb-6">
                  Application Information
                </h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-6 bg-gray-50 p-4 rounded-lg shadow">
                  <div className="space-y-2">
                    <p className="font-semibold text-lg">Request Type:</p>
                    <p className="text-gray-700">
                      {viewModalContent?.basic_information?.requestType ||
                        "N/A"}
                    </p>
                  </div>
                  <div className="space-y-2">
                    <p className="font-semibold text-lg">Permit Duration:</p>
                    <p className="text-gray-700">
                      {viewModalContent?.basic_information?.permitDuration ||
                        "N/A"}
                    </p>
                  </div>
                  <div className="space-y-2">
                    <p className="font-semibold text-lg">Case Description:</p>
                    <p className="text-gray-700">
                      {viewModalContent?.basic_information?.caseDescription ||
                        "N/A"}
                    </p>
                  </div>
                </div>

                <h2 className="text-2xl font-bold font-Roboto text-[#094b72] underline mb-6">
                  Additional Information
                </h2>
                {viewModalContent.status !== "pending" ? (
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-6 bg-gray-50 p-4 rounded-lg shadow">
                    {viewModalContent?.basic_information?.requestType ===
                    "new" ? (
                      <>
                        <div className="space-y-2">
                          <p className="font-semibold text-lg">
                            Passport Number:
                          </p>
                          <p className="text-gray-700">
                            {viewModalContent?.additional_Information?.new
                              ?.passportNumber || "N/A"}
                          </p>
                        </div>
                        <div className="space-y-2">
                          <p className="font-semibold text-lg">
                            Passport File:
                          </p>
                          {viewModalContent?.additional_Information?.new
                            ?.passportFileForNew?.path ? (
                            <div>
                              <a
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleViewFile(
                                    viewModalContent?.additional_Information
                                      ?.new?.passportFileForNew?.path
                                  );
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 hover:underline cursor-pointer py-2"
                              >
                                View Passport
                              </a>
                            </div>
                          ) : (
                            <div className=" text-gray-700 text-sm p-2">
                              No file uploaded
                            </div>
                          )}
                        </div>
                        <div className="space-y-2">
                          <p className="font-semibold text-lg">
                            Birth Certificate:
                          </p>
                          {viewModalContent?.additional_Information?.new
                            ?.birthCertificate?.path ? (
                            <div>
                              <a
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleViewFile(
                                    viewModalContent?.additional_Information
                                      ?.new?.birthCertificate?.path
                                  );
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 hover:underline cursor-pointer py-2"
                              >
                                View Birth Certificate
                              </a>
                            </div>
                          ) : (
                            <div className="text-gray-700 text-sm rounded-lg p-2">
                              No file uploaded
                            </div>
                          )}
                        </div>
                        <div className="space-y-2">
                          <p className="font-semibold text-lg">
                            Birth Certificate Of Partner:
                          </p>
                          {viewModalContent?.additional_Information?.new
                            ?.birthCertificateOfPartnerOpt?.path ? (
                            <div>
                              <a
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleViewFile(
                                    viewModalContent?.additional_Information
                                      ?.new?.birthCertificateOfPartnerOpt?.path
                                  );
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 hover:underline cursor-pointer py-2"
                              >
                                View Birth Certificate
                              </a>
                            </div>
                          ) : (
                            <div className=" text-gray-700 text-sm rounded-lg p-2">
                              No file uploaded
                            </div>
                          )}
                        </div>
                        <div className="space-y-2">
                          <p className="font-semibold text-lg">
                            Marriage Certificate:
                          </p>
                          {viewModalContent?.additional_Information?.new
                            ?.birthCertificateOfPartnerOpt?.path ? (
                            <div>
                              <a
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleViewFile(
                                    viewModalContent?.additional_Information
                                      ?.new?.birthCertificateOfPartnerOpt?.path
                                  );
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 hover:underline cursor-pointer py-2"
                              >
                                View Marriage Certificate
                              </a>
                            </div>
                          ) : (
                            <div className=" text-gray-700 text-sm rounded-lg p-2">
                              No file uploaded
                            </div>
                          )}
                        </div>
                        <div className="space-y-2">
                          <p className="font-semibold text-lg">
                            Antecedent Certificate
                          </p>
                          {viewModalContent?.additional_Information?.new
                            ?.antecedentCertificateNew?.path ? (
                            <div>
                              <a
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleViewFile(
                                    viewModalContent?.additional_Information
                                      ?.new?.antecedentCertificateNew?.path
                                  );
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 hover:underline cursor-pointer py-2"
                              >
                                View Antecedent Certificate
                              </a>
                            </div>
                          ) : (
                            <div className=" text-gray-700 text-sm rounded-lg p-2">
                              No file uploaded
                            </div>
                          )}
                        </div>
                        <div className="space-y-2">
                          <p className="font-semibold text-lg">IBAN:</p>
                          <p className="text-gray-700">
                            {viewModalContent?.additional_Information?.new
                              ?.ibanNew || "N/A"}
                          </p>
                        </div>

                        <div className="space-y-2">
                          <p className="font-semibold text-lg">
                            Other Supporting Documents
                          </p>
                          {viewModalContent?.additional_Information?.new
                            ?.OtherDocuments?.length > 0 ? (
                            <div>
                              {viewModalContent?.additional_Information.new.OtherDocuments.map(
                                (document, index) => (
                                  <React.Fragment key={index}>
                                    <a
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleViewFile(document.path);
                                      }}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-blue-500 hover:underline cursor-pointer block"
                                    >
                                      View Document {index + 1}
                                    </a>
                                    {index !==
                                    viewModalContent?.additional_Information
                                      ?.new?.OtherDocuments.length -
                                      1 ? (
                                      <hr className="my-1" />
                                    ) : null}
                                  </React.Fragment>
                                )
                              )}
                            </div>
                          ) : (
                            <div className=" text-gray-700 text-sm rounded-lg p-2">
                              No file uploaded
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="space-y-2">
                          <p className="font-semibold text-lg">
                            Passport Number:
                          </p>
                          <p className="text-gray-700">
                            {viewModalContent?.additional_Information?.extension
                              ?.passportNumberForExtension || "N/A"}
                          </p>
                        </div>
                        <div className="space-y-2">
                          <p className="font-semibold text-lg">
                            Passport File:
                          </p>
                          {viewModalContent?.additional_Information?.extension
                            ?.passportFileForExtension?.path ? (
                            <div>
                              <a
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleViewFile(
                                    viewModalContent?.additional_Information
                                      ?.extension?.passportFileForExtension
                                      ?.path
                                  );
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 hover:underline cursor-pointer py-2"
                              >
                                View Passport
                              </a>
                            </div>
                          ) : (
                            <div className=" text-gray-700 text-sm rounded-lg p-2">
                              No file uploaded
                            </div>
                          )}
                        </div>
                        <div className="space-y-2">
                          <p className="font-semibold text-lg">
                            Permit Number:
                          </p>
                          <p className="text-gray-700">
                            {viewModalContent?.additional_Information?.extension
                              ?.currentNLPermitNumber || "N/A"}
                          </p>
                        </div>
                        <div className="space-y-2">
                          <p className="font-semibold text-lg">
                            Expiry Permit Number:
                          </p>
                          <p className="text-gray-700">
                            {viewModalContent?.additional_Information?.extension
                              ?.expiryNLPermitNumber || "N/A"}
                          </p>
                        </div>
                        <div className="space-y-2">
                          <p className="font-semibold text-lg">BSN Number:</p>
                          <p className="text-gray-700">
                            {viewModalContent?.additional_Information?.extension
                              ?.bsnNumber || "N/A"}
                          </p>
                        </div>
                        <div className="space-y-2">
                          <p className="font-semibold text-lg">V Number:</p>
                          <p className="text-gray-700">
                            {viewModalContent?.additional_Information?.extension
                              ?.vNumber || "N/A"}
                          </p>
                        </div>
                        <div className="space-y-2">
                          <p className="font-semibold text-lg">IBAN:</p>
                          <p className="text-gray-700">
                            {viewModalContent?.additional_Information?.extension
                              ?.ibanExtension || "N/A"}
                          </p>
                        </div>
                        <div className="space-y-2">
                          <p className="font-semibold text-lg">
                            Antecedent Certificate
                          </p>
                          {viewModalContent?.additional_Information?.extension
                            ?.antecedentCertificateExtension?.path ? (
                            <div>
                              <a
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleViewFile(
                                    viewModalContent?.additional_Information
                                      ?.extension
                                      ?.antecedentCertificateExtension?.path
                                  );
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 hover:underline cursor-pointer py-2"
                              >
                                View Antecedent Certificate
                              </a>
                            </div>
                          ) : (
                            <div className=" text-gray-700 text-sm rounded-lg p-2">
                              No file uploaded
                            </div>
                          )}
                        </div>
                        <div className="space-y-2">
                          <p className="font-semibold text-lg">
                            Other Supporting Documents
                          </p>
                          {viewModalContent?.additional_Information?.extension
                            ?.OtherDocumentsForExtension?.length > 0 ? (
                            <div>
                              {viewModalContent?.additional_Information.extension.OtherDocumentsForExtension.map(
                                (document, index) => (
                                  <React.Fragment key={index}>
                                    <a
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleViewFile(document.path);
                                      }}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-blue-500 hover:underline cursor-pointer block"
                                    >
                                      View Document {index + 1}
                                    </a>
                                    {index !==
                                    viewModalContent?.additional_Information
                                      ?.extension?.OtherDocumentsForExtension
                                      .length -
                                      1 ? (
                                      <hr className="my-1" />
                                    ) : null}
                                  </React.Fragment>
                                )
                              )}
                            </div>
                          ) : (
                            <div className=" text-gray-700 text-sm rounded-lg p-2">
                              No file uploaded
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <div>The application is incomplete</div>
                )}
                <p className="mt-6 text-center text-gray-500 font-medium text-sm">
                  Status: {viewModalContent?.status || "N/A"}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {isModalOpen && (
        <div
          id="popup-modal"
          className="fixed top-0 left-0 right-0 bottom-0 z-50 flex justify-center items-center bg-gray-700 bg-opacity-50"
        >
          <div className="relative p-4 w-full max-w-md max-h-full">
            <div className="relative bg-white rounded-lg shadow">
              <button
                type="button"
                onClick={closeModal}
                className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
              >
                {/* Close icon */}
              </button>
              <div className="p-4 md:p-5 text-center">
                <h2 className="mb-5 text-lg font-bold font-Roboto text-[#094b72] underline">
                  {modalContent.title}
                </h2>
                <p className="mb-5 px-2">{modalContent.message}</p>
                {!modalContent.isLoading && (
                  <div>
                    <button
                      onClick={modalContent.action}
                      className="bg-[#094b72] text-white px-5 py-2 rounded-lg text-sm"
                    >
                      Confirm
                    </button>
                    <button
                      onClick={closeModal}
                      className="ml-3 px-5 py-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200"
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
